/* eslint-disable max-len */
import { RemoteErrorType, RemoteScope } from "../../store/RemoteTypes";
import { RemoteErrors } from "../../store/ReduxState";
import { Map } from "immutable";

export const getErrorValidationMessage =
    (error: Map<RemoteScope, RemoteErrors>, rc: RemoteScope): RemoteErrors | undefined => {
        const errorEntry = error.findEntry((val) => !!val && val.type === RemoteErrorType.VALIDATION);
        let errMessage;
        if (errorEntry) {
            const [scope, reason] = errorEntry;
            errMessage = scope === rc ? reason : undefined;
        }
        return errMessage;
    };

export const hasErrorValidation =
    (error: Map<RemoteScope, RemoteErrors>, rc: RemoteScope): boolean => {
        const errorEntry = error.findEntry((val) => !!val && val.type === RemoteErrorType.VALIDATION);
        let hasError = false;
        if (errorEntry) {
            const [scope] = errorEntry;
            hasError = scope === rc;
        }
        return hasError;
    };


type ErrorData = Array<{ errorCode: number; message: string }> | { message: string };

// FIX IT HERE

enum ErrorCodes {
    NOT_FOUND = 1,
    NOT_CREATED = 2,
    NOT_AUTHORIZED = 3,
    NOT_DELETED = 4,
    NOT_UPDATED = 5,
    USER_NOT_DELETED = 6,
    BAD_REQUEST = 7,
    USER_NOT_UPDATED = 8,
    BAD_EMAIL = 9,
    USER_NOT_FOUND = 10,
    USER_ALREADY_EXISTS = 11,
    USER_BAD_ROLE_NAME = 12,
    USER_NOT_CREATED = 13,
    BAD_IP_ADDRESS = 14,
    API_KEYS_STILL_LINKED_TO_USAGE_PLAN = 15,
    ALREADY_EXISTS = 16,
    CANNOT_BE_DELETED = 17,
    CANNOT_BE_CREATED = 18,
}

const getErrorCodeMessage = (val: ErrorCodes) => {
    const message = ` (Error code: ${val?.toString()})`;
    switch(val) {
    case ErrorCodes.NOT_FOUND:
        return "This object could not be found." + message;
    case ErrorCodes.NOT_CREATED:
        return "This object could not be created." + message;
    case ErrorCodes.NOT_AUTHORIZED:
        return "Not authorized to perform this action." + message;
    case ErrorCodes.NOT_DELETED:
        return "This object could not be deleted." + message;
    case ErrorCodes.NOT_UPDATED:
        return "This object could not be updated." + message;
    case ErrorCodes.USER_NOT_DELETED:
        return "This user could not be deleted." + message;
    case ErrorCodes.BAD_EMAIL:
        return "This email is not valid." + message;
    case ErrorCodes.USER_NOT_UPDATED:
        return "This user could not be updated." + message;
    case ErrorCodes.BAD_REQUEST:
        return "This request is not valid." + message;
    case ErrorCodes.USER_NOT_FOUND:
        return "This user could not be found." + message;
    case ErrorCodes.USER_ALREADY_EXISTS:
        return "This user already exists." + message;
    case ErrorCodes.USER_BAD_ROLE_NAME:
        return "This role name is not valid." + message;
    case ErrorCodes.USER_NOT_CREATED:
        return "This user could not be created." + message;
    case ErrorCodes.BAD_IP_ADDRESS:
        return "There is a problem with the connection." + message;
    case ErrorCodes.API_KEYS_STILL_LINKED_TO_USAGE_PLAN:
        return "This usage plan is in use, therefore it could not be deleted. Make sure you remove all references to this usage plan, so that you are able to delete it." + message;
    case ErrorCodes.ALREADY_EXISTS:
        return "This object alreadt exists." + message;
    case ErrorCodes.CANNOT_BE_DELETED:
        return "This object could not be deleted." + message;
    case ErrorCodes.CANNOT_BE_CREATED:
        return "This object could not be created." + message;
    default:
        return "We don't know what wen't wrong." +  message;
    }
};

export const getRawValidationMessages = (data: ErrorData) => {
    let messages = "";
    if (Array.isArray(data)) {
        data.forEach(entry => {
            if (entry.errorCode in ErrorCodes) {
                messages += `${getErrorCodeMessage(entry.errorCode)}\n`;
            } else {
                messages += `${entry.message}\n`;
            }
        });
    } else {
        messages = data.message;
    }
    return messages;
};
