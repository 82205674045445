/* eslint-disable */
import { AxiosRequestConfig, AxiosResponse, CancelTokenSource, ResponseType } from "axios";
import { DispatchFunc } from "./ActionTypes";
import { APIRequest } from "./API/Types";
import { AppClientRequest } from "./APIAppClients/Types";
import { CertificateRequest } from "./APICertificates/Types";
import { APISubscriberRequest } from "./APISubscribers/Types";
import { UsagePlanRequest } from "./APIUsagePlans/Types";
import { ConsumerRequest } from "./Consumers/Types";
import { OrganisationRequest } from "./Organisations/Types";
import { ReduxState, RemoteState } from "./ReduxState";
import { Mary } from "@vwpfs/vwpfs-mary-react-comp-lib";
import { verifyToken } from "./keycloak";

export const DEFAULT_PAGE_SIZE = 25;

export enum RemoteErrorType {
    DEFAULT = "DEFAULT",
    VALIDATION = "VALIDATION",
    SYSTEM_ERROR = "SYSTEM_ERROR",
    UNKNOWN_ERROR = "UNKNOWN_ERROR",
    AUTHORIZATION = "AUTHORIZATION_ERROR",
}

export type MethodType = "GET" | "PUT" | "POST" | "DELETE" | "OPTIONS" | "HEAD" | "PATCH";

export interface HeadersType {
    [key: string]: string;
}

export interface RemoteConfig<T extends RemoteScope> {
    scope: T;
    identifier?: string;
    pathMapper: string | ((state: ReduxState, ctx: RemoteContexts[T]) => string);
    responseMapper?: (resp: AxiosResponse<any>, state: ReduxState, ctx: RemoteContexts[T]) => Mary.utils.Opt<{}>;
    method?: MethodType;
    bodyMapper?: (state: ReduxState, ctx: RemoteContexts[T]) => {} | undefined;
    source?: CancelTokenSource;
    paramsMapper?: (state: ReduxState, ctx: RemoteContexts[T]) => {};
    resMapper: (resp: AxiosResponse, s: ReduxState, ctx: RemoteContexts[T]) => RemoteState[T];
    onSuccess?: (dispatch: DispatchFunc, state: ReduxState, ctx: RemoteContexts[T]) => void;
    onError?: (dispatch: DispatchFunc, state: ReduxState, ctx: RemoteContexts[T]) => void;
    onInit?: (dispatch: DispatchFunc, state: ReduxState, ctx: RemoteContexts[T]) => void;
    headers?: (state: ReduxState, ctx: RemoteContexts[T]) => HeadersType;
    // headers?: HeadersType;
    dontIncludeAuthHeader?: boolean;
    responseType?: ResponseType;
}

// TODO: Remove if not used
export interface RequestObject {
    totalElements: number;
    totalPages: number;
    number: number;
}

export interface RefObject {
    id: number;
}

export const hasValue = (value?: string | number | RefObject) => !!value || value === 0;

export const hasRequiredFields =
    <T, K extends keyof T>(obj: T, fields: K[]) =>
        fields.every(k => hasValue(obj[k] as unknown as string | number | RefObject));

/**
 *
 */
export type RemoteStatusValues = Readonly<{
    Statuses: Array<{
        Label: string;
        Name: string;
    }>;
}>;

/**
 *
 */
export enum RemoteScope {
    // INIT = "INIT",
    API_RESULTS = "API_RESULTS",
    API_DETAILS = "API_DETAILS",
    API_UPDATE = "API_UPDATE",
    APPCLIENT_RESULTS = "APPCLIENT_RESULTS",
    APPCLIENT_DETAILS = "APPCLIENT_DETAILS",
    APPCLIENT_CREATE = "APPCLIENT_CREATE",
    APPCLIENT_EDIT = "APPCLIENT_EDIT",
    APPCLIENT_DELETE = "APPCLIENT_DELETE",
    CERTIFICATE_RESULTS = "CERTIFICATE_RESULTS",
    CERTIFICATE_DETAILS = "CERTIFICATE_DETAILS",
    CERTIFICATE_CREATE = "CERTIFICATE_CREATE",
    CERTIFICATE_EDIT = "CERTIFICATE_EDIT",
    CERTIFICATE_DELETE = "CERTIFICATE_DELETE",
    API_SUBSCRIBER_RESULTS = "API_SUBSCRIBER_RESULTS",
    API_SUBSCRIBER_DETAILS = "API_SUBSCRIBER_DETAILS",
    API_SUBSCRIBER_CREATE = "API_SUBSCRIBER_CREATE",
    API_SUBSCRIBER_EDIT = "API_SUBSCRIBER_EDIT",
    API_SUBSCRIBER_DELETE = "API_SUBSCRIBER_DELETE",
    USAGEPLAN_RESULTS = "USAGEPLAN_RESULTS",
    USAGEPLAN_DETAILS = "USAGEPLAN_DETAILS",
    USAGEPLAN_CREATE = "USAGEPLAN_CREATE",
    USAGEPLAN_EDIT = "USAGEPLAN_EDIT",
    USAGEPLAN_DELETE = "USAGEPLAN_DELETE",
    GENERIC_CONSUMER_RESULTS = "GENERIC_CONSUMER_RESULTS",
    CONSUMER_RESULTS = "CONSUMER_RESULTS",
    CONSUMER_DETAILS = "CONSUMER_DETAILS",
    CONSUMER_CREATE = "CONSUMER_CREATE",
    CONSUMER_EDIT = "CONSUMER_EDIT",
    CONSUMER_DELETE = "CONSUMER_DELETE",
    CONSUMER_SUBSCRIPTIONS = "CONSUMER_SUBSCRIPTIONS",
    CONSUMER_OVERVIEW_RESULTS = "CONSUMER_OVERVIEW_RESULTS",
    ORGANISATION_RESULTS = "ORGANISATION_RESULTS",
    ORGANISATION_DETAILS = "ORGANISATION_DETAILS",
    ORGANISATION_CREATE = "ORGANISATION_CREATE",
    ORGANISATION_EDIT = "ORGANISATION_EDIT",
    ORGANISATION_DELETE = "ORGANISATION_DELETE",
}

/**
 *
 */
export interface RemoteContexts {
    [RemoteScope.API_RESULTS]: undefined;
    [RemoteScope.API_DETAILS]: {
        id: string;
    };
    [RemoteScope.API_UPDATE]: {
        id: string;
        modalId: string;
        api: APIRequest;
    };
    [RemoteScope.APPCLIENT_RESULTS]: {
        apiId: string;
    };
    [RemoteScope.APPCLIENT_DETAILS]: {
        id: string;
        apiId: string;
        modalID: string;
    };
    [RemoteScope.APPCLIENT_CREATE]: {
        apiId: string;
        appClient: AppClientRequest;
    };
    [RemoteScope.APPCLIENT_EDIT]: {
        apiId: string;
        appClient: AppClientRequest;
        modalID: string;
    };
    [RemoteScope.APPCLIENT_DELETE]: {
        apiId: string;
        id: string;
        modalID: string;
    };
    [RemoteScope.CERTIFICATE_RESULTS]: {
        apiId: string;
        subscriberId: string;
    };
    [RemoteScope.CERTIFICATE_DETAILS]: {
        id: string;
        apiId: string;
        subscriberId: string;
    };
    [RemoteScope.CERTIFICATE_CREATE]: {
        apiId: string;
        subscriberId: string;
        certificate: CertificateRequest;
    };
    [RemoteScope.CERTIFICATE_EDIT]: {
        apiId: string;
        subscriberId: string;
        certificateId: string;
        modalId: string;
    };
    [RemoteScope.CERTIFICATE_DELETE]: {
        id: string;
        modalID: string;
        apiId: string;
        subscriberId: string;
    };
    [RemoteScope.API_SUBSCRIBER_RESULTS]: {
        apiId: string;
    };
    [RemoteScope.API_SUBSCRIBER_DETAILS]: {
        apiId: string;
        subscriberId: string;
        edit?: boolean;
        retry?: boolean;
    };
    [RemoteScope.API_SUBSCRIBER_CREATE]: {
        apiId: string;
        subscriber: APISubscriberRequest;
    };
    [RemoteScope.API_SUBSCRIBER_EDIT]: {
        apiId: string;
        subscriber: APISubscriberRequest;
        subscriberId: string;
    };
    [RemoteScope.API_SUBSCRIBER_DELETE]: {
        apiId: string;
        id: string;
        modalID: string;
    };
    [RemoteScope.USAGEPLAN_RESULTS]: {
        apiId: string;
    };
    [RemoteScope.USAGEPLAN_DETAILS]: {
        apiId: string;
        usageplanId: string;
    };
    [RemoteScope.USAGEPLAN_DELETE]: {
        apiId: string;
        id: string;
        modalID: string;
    };
    [RemoteScope.USAGEPLAN_CREATE]: {
        apiId: string;
        usageplan: UsagePlanRequest;
    };
    [RemoteScope.USAGEPLAN_EDIT]: {
        apiId: string;
        usageplanId: string;
        usageplan: UsagePlanRequest;
    };
    [RemoteScope.GENERIC_CONSUMER_RESULTS]: undefined;
    [RemoteScope.CONSUMER_RESULTS]: {
        orgId: string;
        onSuccess?: () => void;
    };
    [RemoteScope.CONSUMER_DETAILS]: {
        id: string;
        orgId: string;
    };
    [RemoteScope.CONSUMER_CREATE]: {
        orgId: string;
        consumer: ConsumerRequest;
    };
    [RemoteScope.CONSUMER_EDIT]: {
        orgId: string;
        consumer: ConsumerRequest;
        consumerId: string;
    };
    [RemoteScope.CONSUMER_DELETE]: {
        id: string;
        modalID: string;
        orgId: string;
    };
    [RemoteScope.CONSUMER_SUBSCRIPTIONS]: {
        orgId: string;
        consumerId: string;
    };
    [RemoteScope.CONSUMER_OVERVIEW_RESULTS]: undefined;
    [RemoteScope.ORGANISATION_RESULTS]: undefined;
    [RemoteScope.ORGANISATION_DETAILS]: {
        id: string;
    };
    [RemoteScope.ORGANISATION_CREATE]: {
        org: OrganisationRequest;
    };
    [RemoteScope.ORGANISATION_EDIT]: {
        org: OrganisationRequest;
        orgId: string;
    };
    [RemoteScope.ORGANISATION_DELETE]: {
        id: string;
        modalID: string;
    };
}

/**
 * FIXME user auth headers should be included as part of the state's cached
 * AxiosInstance.
 */
export const buildRequest =
    async <T extends RemoteScope>(
        state: ReduxState,
        trigger: RemoteConfig<T>,
        ctx: RemoteContexts[T],
    ): Promise<AxiosRequestConfig> => {
        let headers: HeadersType = {
            // 'defaultHeader': 'test'
        };

        const keycloak = state.prop("user").get().keycloak;
        let updatedToken = "";
        if(keycloak) {
            updatedToken = await verifyToken(keycloak) ?? "";
        }

        if (trigger.headers) {
            headers = { ...headers, ...trigger.headers };
        }

        if (trigger.dontIncludeAuthHeader !== true) {
            headers = { ...headers, Authorization: `Bearer ${updatedToken}` };
        }

        return {
            method: trigger.method || "GET",
            url: `${typeof trigger.pathMapper === "string"
                ? trigger.pathMapper : trigger.pathMapper(state, ctx)}`,
            // headers: state.mapProp("mary", u => u.map(authHeader).getOpt()),
            headers,
            responseType: trigger.responseType,
            params: !trigger.paramsMapper ? undefined : trigger.paramsMapper(state, ctx),
            data: !trigger.bodyMapper ? undefined : trigger.bodyMapper(state, ctx),
        };
    };
