import React from "react";
import { OrderedSet } from "immutable";
import { PageDisplay, Pages } from "../../../../store/AppDisplays";
import { Mary } from "@vwpfs/vwpfs-mary-react-comp-lib";


// StateProps are props derived from Redux state through MapStateToProps.
interface StateProps {
}

/**
 *
 */
interface OwnProps {
    tabs: OrderedSet<Pages>;
}

/**
 *
 */
interface DispatchProps {
}

/**
 *
 */
type Props = OwnProps & StateProps & DispatchProps;


/**
 *
 */
export const DashboardMenu = (props: Props) => (
    <Mary.organisms.menu.Menu
        orientation={Mary.organisms.menu.MenuOrientation.VERTICAL}
        items={getItems(props)}
    />
);

/**
 *
 * @param props
 */
const getItems = (props: Props): Mary.organisms.menu.MenuItem[] =>
    props.tabs
        .map((page: Pages): Mary.organisms.menu.MenuItem => ({
            children: PageDisplay[page].title,
            navLink: PageDisplay[page].path,
            beta: PageDisplay[page].beta,
        }))
        .toArray();
