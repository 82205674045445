export enum LoggingLevel {
    INFO = "INFO",
    ERROR = "ERROR",
    OFF = "OFF",
}

export enum SecurityType {
    CERTIFICATE = "Certificate",
    APPCLIENT = "App Client",
}

export interface API {
    id: string;
    description: string;
    name: string;
    tags: {
        stage: string;
        riskLevel: string;
        stableVersion: string;
        custom_domain: string;
    };
    type?: SecurityType;
}

export interface APIDetail extends API {
    logging: {
        level: LoggingLevel;
        dataLoggingEnabled: boolean;
    };
    metricsEnabled: boolean;
}

export interface APIs {
    content: API[];
}

export interface APIDetails {
    content: APIDetail;
}

export interface APIRequest {
    loggingLevel?: LoggingLevel;
    dataTraceEnabled?: boolean;
    metricsEnabled?: boolean;
}
