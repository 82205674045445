/* eslint-disable @typescript-eslint/require-await */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { OrderedSet } from "immutable";
import { Action } from "redux";
import { ActionTypeKeys, Thunk } from "./ActionTypes";
import { PageDisplay, Pages } from "./AppDisplays";
import {
    UserInfo,
} from "./AppTypes";
import { ReducerFn, clearState } from "./ReduxState";
import { Mary } from "@vwpfs/vwpfs-mary-react-comp-lib";

export type AppInitActionType = Readonly<Action<ActionTypeKeys.APP_INIT> & { userInfo: UserInfo }>;
/**
 *
 */
export type AppInitAction = Readonly<
Action<ActionTypeKeys.APP_INIT> & {
    userInfo?: UserInfo;
}>;

export const appInit: (userInfo?: UserInfo) => Thunk<void> =
    (userInfo?: UserInfo) => async (dispatch, getState) => {

        const state = getState();
        if (state.prop("user").isPresent()) {
            console.log("Claude already initialised, updated token.");
        }

        // initialize the view
        dispatch({
            type: ActionTypeKeys.APP_INIT,
            userInfo: userInfo,
        });
    };

// TODO: This state is only set once. Consider move to default state.
export const appInitReducer: ReducerFn<AppInitActionType> =
    (state, action) => {
        const userRole = action.userInfo ? action.userInfo.userRole : undefined;
        localStorage.setItem("token", action.userInfo?.token ?? "");
        return (state
            .setProp("user", Mary.utils.Optional.of({ ...action.userInfo }))
            .setProp(
                "pages",
                state.prop("pages").updateWith({
                    pages: userRole ? OrderedSet(Object.keys(Pages).map(key => Pages[key] as Pages)
                        .filter(
                            page => PageDisplay[page].roles.includes(userRole),
                        )) : OrderedSet(Object.keys(Pages).map(key => Pages[key] as Pages)),
                }),
            ));
    };

/**
 *
 */
export type SignOutAction = Readonly<
Action<ActionTypeKeys.SIGN_OUT>>;

export const signout: () => Thunk<void> =
    () => async (dispatch, _getState) => {
        dispatch({
            type: ActionTypeKeys.SIGN_OUT,
        });
    };

/**
 *
 */
export const signoutReducer:
ReducerFn<SignOutAction> =
     (s, _a) => clearState(s);


export const toggleSidebar: (force?: boolean) => Thunk<void> =
    (force) => async (dispatch, _getState) => {
        dispatch({
            type: ActionTypeKeys.TOGGLE_SIDEBAR,
            force,
        });
    };


/**
 *
 */
export type ToggleSidebarAction = Readonly<
Action<ActionTypeKeys.TOGGLE_SIDEBAR> & {
    force?: boolean;
}>;

/**
 *
 */
export const toggleSidebarReducer:
ReducerFn<ToggleSidebarAction> =
     (s, a) => {
         const current = a.force !== undefined ? a.force : !s.prop("sidebarCollapsed");
         localStorage.setItem("isCollapsed", JSON.stringify(current));
         return (
             s.setProp(
                 "sidebarCollapsed",
                 current,
             )
         );
     };
