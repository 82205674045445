import { Action } from "redux";
import { ActionTypeKeys } from "../ActionTypes";
import { ReducerFn } from "../ReduxState";
import { RemoteScope } from "../RemoteTypes";
import { ConsumerRequest } from "./Types";
import { Mary } from "@vwpfs/vwpfs-mary-react-comp-lib";

/**
 *
 */
export type InitUpdateConsumerAction = Readonly<
Action<ActionTypeKeys.INIT_UPDATE_CONSUMER> & {
}
>;

/**
 *
 *
 */
export const initUpdateConsumer =
    (): InitUpdateConsumerAction => ({
        type: ActionTypeKeys.INIT_UPDATE_CONSUMER,
    });

/**
 *
 * @param s
 * @param a
 */
export const initUpdateConsumerReducer:
ReducerFn<InitUpdateConsumerAction> =
    (s, _a) => {
        const consumerDetails = s.prop("remote").prop(RemoteScope.CONSUMER_DETAILS);
        return (
            s.setProp(
                "consumerRequest",
                s.prop("consumerRequest").update(() => consumerDetails?.content ?? {}),
            )
        );
    };

/**
 *
 */
export type UpdateConsumerAction = Readonly<
Action<ActionTypeKeys.UPDATE_CONSUMER> & {
    consumer?: Partial<ConsumerRequest>;
}
>;

/**
 *
 *
 */
export const updateConsumer =
    (consumer: ConsumerRequest): UpdateConsumerAction => ({
        type: ActionTypeKeys.UPDATE_CONSUMER,
        consumer: consumer,
    });

/**
 *
 * @param s
 * @param a
 */
export const updateConsumerReducer:
ReducerFn<UpdateConsumerAction> =
    (s, a) => (
        s.setProp(
            "consumerRequest",
            s.prop("consumerRequest").updateWith(
                a.consumer ?? {},
            ),
        )
    );

/**
 *
 */
export type ClearConsumerAction = Readonly<
Action<ActionTypeKeys.CLEAR_CONSUMER>
>;

/**
 *
 * @param keys
 */
export const clearConsumer:
() => ClearConsumerAction =
    () => ({
        type: ActionTypeKeys.CLEAR_CONSUMER,
    });

/**
 *
 */
export const clearConsumerReducer:
ReducerFn<ClearConsumerAction> =
    (s, _a) => (
        s.setProp(
            "consumerRequest",
            Mary.utils.State.create<ConsumerRequest>(() => ({})),
        )
    );
