/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { AxiosResponse } from "axios";
import { UsagePlan, UsagePlanDetails, UsagePlans } from "./Types";


export const mapUsagePlanRequests =
    (resp: AxiosResponse): UsagePlans => {
        const requests = (resp.data.content ? resp.data.content as UsagePlan[]
            : resp.data as UsagePlan[]);

        const usageplans = {
            content: requests,
        };

        return usageplans;
    };

/**
 *
 * @param resp
 */
export const mapUsagePlanDetailsRequests =
 (resp: AxiosResponse): UsagePlanDetails => {
     const request = (resp.data.content ? resp.data.content as UsagePlan
         : resp.data as UsagePlan);

     const usageplan = {
         content: request,
     };

     return usageplan;
 };
