/* eslint-disable */
import Axios, { AxiosInstance, CancelTokenSource } from "axios";
import { Map, OrderedSet } from "immutable";
import { Action } from "redux";
import { ActionTypeKeys, Config, ReducerFunc } from "./ActionTypes";
import { APIDetails, APIRequest, APIs } from "./API/Types";
import { AppClientDetails, AppClientRequest, AppClients } from "./APIAppClients/Types";
import { CertificateDetails, CertificateRequest, Certificates } from "./APICertificates/Types";
import {
    APISubscriberDetails,
    APISubscriberRequest,
    APISubscribers,
} from "./APISubscribers/Types";
import { UsagePlanDetails, UsagePlanRequest, UsagePlans } from "./APIUsagePlans/Types";
import { Pages } from "./AppDisplays";
import { APIManagerRole, UserInfo } from "./AppTypes";
import { ConsumerDetails, ConsumerOverviewDetails, ConsumerRequest, Consumers } from "./Consumers/Types";
import { OrganisationDetails, OrganisationRequest, Organisations } from "./Organisations/Types";
import { RemoteErrorType, RemoteScope } from "./RemoteTypes";
import * as OAS from "../App/utils/OAS3";
import { Mary } from "@vwpfs/vwpfs-mary-react-comp-lib";

export const isRemoteLoading =
    (state: ReduxState, scope: RemoteScope): boolean =>
        state.mapProp("remotes", r => r.has(scope));

export const isRemoteLoadingAny =
    (state: ReduxState): boolean =>
        state.mapProp("remotes", r => !r.isEmpty());

export interface RemoteErrors {
    message: string;
    type: RemoteErrorType;
}

export type CurrentPage = Readonly<{
    pages: OrderedSet<Pages>;
}>;

export interface HasRole {
    audit?: boolean;
    devops?: boolean;
    admin?: boolean;
    adminOrDevops?: boolean;
}

export const getHasRole = (s: ReduxState): HasRole => ({
    audit: s.prop("user").isPresent() ? s.prop("user").get().userRole === APIManagerRole.audit : undefined,
    devops: s.prop("user").isPresent() ? s.prop("user").get().userRole === APIManagerRole.devops : undefined,
    admin: s.prop("user").isPresent() ? s.prop("user").get().userRole === APIManagerRole.admin : undefined,
    adminOrDevops: s.prop("user").isPresent() ?
        (s.prop("user").get().userRole === APIManagerRole.admin
        || s.prop("user").get().userRole === APIManagerRole.devops) : undefined,
});

interface TState {
    conf: Config;
    client: AxiosInstance;
    remotes: Map<RemoteScope, Mary.utils.Opt<CancelTokenSource>>;
    remote: Mary.utils.State<RemoteState>;
    remoteErrors: Map<RemoteScope, RemoteErrors>;
    mary?: Mary.utils.MaryState;
    filters: Mary.utils.State<FilterStates>;
    user: Mary.utils.Optional<UserInfo>;
    pages: Mary.utils.State<CurrentPage>;
    certificateRequest: Mary.utils.State<CertificateRequest>;
    appClientRequest: Mary.utils.State<AppClientRequest>;
    apiSubscriberRequest: Mary.utils.State<APISubscriberRequest>;
    consumerRequest: Mary.utils.State<ConsumerRequest>;
    orgRequest: Mary.utils.State<OrganisationRequest>;
    usageplanRequest: Mary.utils.State<UsagePlanRequest>;
    apiRequest: Mary.utils.State<APIRequest>;
    sidebarCollapsed: boolean;
}

/**
 *
 */
export interface RemoteState {
    [RemoteScope.API_RESULTS]?: APIs;
    [RemoteScope.API_DETAILS]?: APIDetails;
    [RemoteScope.API_UPDATE]?: APIRequest;
    [RemoteScope.APPCLIENT_RESULTS]?: AppClients;
    [RemoteScope.APPCLIENT_DETAILS]?: AppClientDetails;
    [RemoteScope.APPCLIENT_CREATE]?: AppClientDetails;
    [RemoteScope.APPCLIENT_EDIT]?: AppClientDetails;
    [RemoteScope.APPCLIENT_DELETE]?: {};
    [RemoteScope.CERTIFICATE_RESULTS]?: Certificates;
    [RemoteScope.CERTIFICATE_DETAILS]?: CertificateDetails;
    [RemoteScope.CERTIFICATE_CREATE]?: CertificateDetails;
    [RemoteScope.CERTIFICATE_EDIT]?: CertificateDetails;
    [RemoteScope.CERTIFICATE_DELETE]?: {};
    [RemoteScope.API_SUBSCRIBER_RESULTS]?: APISubscribers;
    [RemoteScope.API_SUBSCRIBER_DETAILS]?: APISubscriberDetails;
    [RemoteScope.API_SUBSCRIBER_CREATE]?: APISubscriberDetails;
    [RemoteScope.API_SUBSCRIBER_EDIT]?: APISubscriberDetails;
    [RemoteScope.API_SUBSCRIBER_DELETE]?: {};
    [RemoteScope.USAGEPLAN_RESULTS]?: UsagePlans;
    [RemoteScope.USAGEPLAN_DETAILS]?: UsagePlanDetails;
    [RemoteScope.USAGEPLAN_CREATE]?: UsagePlanDetails;
    [RemoteScope.USAGEPLAN_EDIT]?: UsagePlanDetails;
    [RemoteScope.USAGEPLAN_DELETE]?: {};
    [RemoteScope.GENERIC_CONSUMER_RESULTS]?: Consumers;
    [RemoteScope.CONSUMER_RESULTS]?: Consumers;
    [RemoteScope.CONSUMER_DETAILS]?: ConsumerDetails;
    [RemoteScope.CONSUMER_CREATE]?: ConsumerDetails;
    [RemoteScope.CONSUMER_EDIT]?: ConsumerDetails;
    [RemoteScope.CONSUMER_DELETE]?: {};
    [RemoteScope.CONSUMER_SUBSCRIPTIONS]?: APISubscribers;
    [RemoteScope.CONSUMER_OVERVIEW_RESULTS]?: ConsumerOverviewDetails;
    [RemoteScope.ORGANISATION_RESULTS]?: Organisations;
    [RemoteScope.ORGANISATION_DETAILS]?: OrganisationDetails;
    [RemoteScope.ORGANISATION_CREATE]?: OrganisationDetails;
    [RemoteScope.ORGANISATION_EDIT]?: OrganisationDetails;
    [RemoteScope.ORGANISATION_DELETE]?: {};
}

export type ReduxState = Mary.utils.State<TState>;

export type ReducerFn<A extends Action<ActionTypeKeys>> =
    ReducerFunc<ReduxState, A>;

export type Filters = Readonly<{
}>;

export type FilterStates = {
    [K in keyof Filters]: Mary.utils.State<Filters[K]>;
};

export const clearState = (s: ReduxState) => s.clear({mary: true, conf: true});

export const ReduxStateInit: (config: Config) => Mary.utils.StateInit<TState> =
    config => (): TState => ({
        conf: config,
        client: Axios.create({
            baseURL: config.apiEndpoint,
        }),
        remotes: Map() as Map<RemoteScope, Mary.utils.Opt<CancelTokenSource>>,
        remote: Mary.utils.State.create<RemoteState>(() => ({})),
        remoteErrors: Map() as Map<RemoteScope, RemoteErrors>,
        mary: undefined,
        filters: Mary.utils.State.create<FilterStates>(() => ({})),
        user: Mary.utils.Optional.empty(),
        pages: Mary.utils.State.create<CurrentPage>(() => ({
            pages: OrderedSet([]),
        })),
        certificateRequest: Mary.utils.State.create<CertificateRequest>(() => ({})),
        appClientRequest: Mary.utils.State.create<AppClientRequest>(() => ({})),
        apiSubscriberRequest: Mary.utils.State.create<APISubscriberRequest>(() => ({})),
        consumerRequest: Mary.utils.State.create<ConsumerRequest>(() => ({})),
        orgRequest: Mary.utils.State.create<OrganisationRequest>(() => ({})),
        usageplanRequest: Mary.utils.State.create<UsagePlanRequest>(() => ({
        })),
        apiRequest: Mary.utils.State.create<APIRequest>(() => ({
        })),
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        sidebarCollapsed: JSON.parse(localStorage.getItem("isCollapsed") ?? "false") ?? false,
    });
