import Keycloak from "keycloak-js";

export enum APIManagerRole {
    audit = "audit", // read-only
    admin = "admin",
    devops = "devops",
    NONE = "NONE",
}

export type UserInfo = Readonly<{
    token?: string;
    keycloak?: Keycloak;
    userName?: string;
    userRole?: APIManagerRole;
}>;
