/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { AxiosResponse } from "axios";
import { ReduxState } from "../ReduxState";
import { RemoteScope } from "../RemoteTypes";
import { APISubscriber, APISubscriberDetail, APISubscriberDetails, APISubscribers } from "./Types";


export const mapAPISubscriberRequests =
    (resp: AxiosResponse, s: ReduxState): APISubscribers => {
        const requests = (resp.data.content ? resp.data.content as APISubscriber[]
            : resp.data as APISubscriber[]);

        const orgResults = s.prop("remote").prop(RemoteScope.ORGANISATION_RESULTS);
        const apiResults = s.prop("remote").prop(RemoteScope.API_RESULTS);
        const consumerResults = s.prop("remote").prop(RemoteScope.GENERIC_CONSUMER_RESULTS);

        const subscribers = {
            content: (orgResults && apiResults) || (orgResults && consumerResults) ? requests.map(subscriber => ({
                ...subscriber,
                apiName: apiResults?.content.find(api => api.id === subscriber.apiId)?.name,
                organizationName: orgResults?.content.find(org => org.id === subscriber.organizationId)?.name,
                consumerName: consumerResults?.content.find(consumer => consumer.id === subscriber.consumerId)?.name,
            })) : [],
        };

        return subscribers;
    };

/**
 *
 * @param resp
 */
export const mapAPISubscriberDetailsRequests =
 (resp: AxiosResponse, s: ReduxState): APISubscriberDetails => {
     const request = (resp.data.content ? resp.data.content as APISubscriberDetail
         : resp.data as APISubscriberDetail);


     const orgResults = s.prop("remote").prop(RemoteScope.ORGANISATION_RESULTS);
     const consumerResults = s.prop("remote").prop(RemoteScope.GENERIC_CONSUMER_RESULTS);
     const usagePlanResults = s.prop("remote").prop(RemoteScope.USAGEPLAN_RESULTS);
     const apiDetails = s.prop("remote").prop(RemoteScope.API_DETAILS);
     const subscriber = {
         content: (orgResults && consumerResults && usagePlanResults && apiDetails) ? {
             ...request,
             organizationName: orgResults?.content.find(org => org.id === request.organizationId)?.name,
             consumerName: consumerResults?.content.find(consumer => consumer.id === request.consumerId)?.name,
             usagePlanName: usagePlanResults?.content.find(plan => plan.id === request.usagePlanId)?.name,
             apiName: apiDetails?.content.name,
         } : {} as APISubscriberDetail,
         allContextLoaded: !!orgResults && !!consumerResults && !!usagePlanResults && !!apiDetails,
     };

     return subscriber;
 };
