/* eslint-disable */
import { mapAPIDetailsRequests, mapAPIRequests, mapAPIUpdateRequest } from "./API/RemoteConfig";
import { initUpdateAPI } from "./API/UpdateType";
import { initUpdateAppClient } from "./APIAppClients/CreateType";
import { mapAppClientDetailsRequests, mapAppClientRequests } from "./APIAppClients/RemoteConfig";
import { AppClientRequest } from "./APIAppClients/Types";
import { mapCertificateDetailsRequests,
    mapCertificateDetailsRequestsOnCreate,
    mapCertificateRequests } from "./APICertificates/RemoteConfig";
import { CertificateRequest } from "./APICertificates/Types";
import { initUpdateAPISubscriber } from "./APISubscribers/CreateType";
import { mapAPISubscriberDetailsRequests, mapAPISubscriberRequests } from "./APISubscribers/RemoteConfig";
import { APISubscriberRequest } from "./APISubscribers/Types";
import { initUpdateUsagePlan } from "./APIUsagePlans/CreateType";
import { mapUsagePlanDetailsRequests, mapUsagePlanRequests } from "./APIUsagePlans/RemoteConfig";
import { UsagePlanRequest } from "./APIUsagePlans/Types";
import { initUpdateConsumer } from "./Consumers/CreateType";
import { mapConsumerDetailsRequests, mapConsumerOverviewDetailsRequests,
    mapConsumerRequests } from "./Consumers/RemoteConfig";
import { ConsumerRequest } from "./Consumers/Types";
import { initUpdateOrg } from "./Organisations/CreateType";
import { mapOrganisationDetailsRequests, mapOrganisationRequests } from "./Organisations/RemoteConfig";
import { OrganisationRequest } from "./Organisations/Types";
import { remoteClearError, remoteClearResponse, remoteTrigger } from "./RemoteActions";
import {
    RemoteConfig,
    RemoteScope,
} from "./RemoteTypes";
import { Mary } from "@vwpfs/vwpfs-mary-react-comp-lib";

/**
 *
 */
export const RemoteConfigs: { [K in RemoteScope]: RemoteConfig<K>; } = {
    [RemoteScope.API_RESULTS]: {
        scope: RemoteScope.API_RESULTS,
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.API_RESULTS));
            d(remoteClearError(RemoteScope.API_RESULTS));
        },
        pathMapper: () => "/apis",
        resMapper: mapAPIRequests,
    },
    [RemoteScope.API_DETAILS]: {
        scope: RemoteScope.API_DETAILS,
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.API_DETAILS));
            d(remoteClearError(RemoteScope.API_DETAILS));
        },
        pathMapper: (s, ctx) => `/apis/${ctx.id}`,
        resMapper: mapAPIDetailsRequests,
        onSuccess: (d, _s, _ctx) => {
            d(initUpdateAPI());
        },
    },
    [RemoteScope.API_UPDATE]: {
        scope: RemoteScope.API_UPDATE,
        method: "PUT",
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.API_UPDATE));
            d(remoteClearError(RemoteScope.API_UPDATE));
        },
        pathMapper: (_s, ctx) => `apis/${ctx.id}`,
        resMapper: mapAPIUpdateRequest,
        bodyMapper: (s, ctx) => ctx.api,
        onSuccess: (d, _s, ctx) => {
            d(Mary.utils.modalClose(ctx.modalId));
            d(remoteTrigger(RemoteScope.API_DETAILS, {
                id: ctx.id,
            }));
        },
    },
    [RemoteScope.APPCLIENT_RESULTS]: {
        scope: RemoteScope.APPCLIENT_RESULTS,
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.APPCLIENT_RESULTS));
            d(remoteClearError(RemoteScope.APPCLIENT_RESULTS));
        },
        pathMapper: (_s, ctx) => `apis/${ctx.apiId}/appclient`,
        resMapper: mapAppClientRequests,
    },
    [RemoteScope.APPCLIENT_DETAILS]: {
        scope: RemoteScope.APPCLIENT_DETAILS,
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.APPCLIENT_DETAILS));
            d(remoteClearError(RemoteScope.APPCLIENT_DETAILS));
        },
        pathMapper: (_s, ctx) => `apis/${ctx.apiId}/appclient/${ctx.id}`,
        resMapper: mapAppClientDetailsRequests,
        onSuccess: (d, _s, _ctx) => {
            d(initUpdateAppClient());
        },
    },
    [RemoteScope.APPCLIENT_CREATE]: {
        scope: RemoteScope.APPCLIENT_CREATE,
        method: "POST",
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.APPCLIENT_CREATE));
            d(remoteClearError(RemoteScope.APPCLIENT_CREATE));
        },
        pathMapper: (_s, ctx) => `apis/${ctx.apiId}/appclient`,
        resMapper: mapAppClientDetailsRequests,
        bodyMapper: (s, ctx) => {
            const appClient: AppClientRequest = {
                ...ctx.appClient,
            };
            return appClient;
        },
        onSuccess: (d, _s, ctx) => {
            d(remoteTrigger(RemoteScope.APPCLIENT_RESULTS, {apiId: ctx?.apiId}));
            d(Mary.utils.modalClose("app-client"));
        },
    },
    [RemoteScope.APPCLIENT_EDIT]: {
        scope: RemoteScope.APPCLIENT_EDIT,
        method: "PUT",
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.APPCLIENT_EDIT));
            d(remoteClearError(RemoteScope.APPCLIENT_EDIT));
        },
        pathMapper: (_s, ctx) => `apis/${ctx.apiId}/appclient/${ctx.appClient.clientId ?? ""}`,
        resMapper: mapAppClientDetailsRequests,
        bodyMapper: (s, ctx) => {
            const appClient = {
                clientName: ctx.appClient.clientName,
            };
            return appClient;
        },
        onSuccess: (d, s, ctx) => {
            d(remoteTrigger(RemoteScope.APPCLIENT_RESULTS, {apiId: ctx?.apiId}));
            d(Mary.utils.modalClose(ctx.modalID));
        },
    },
    [RemoteScope.APPCLIENT_DELETE]: {
        scope: RemoteScope.APPCLIENT_DELETE,
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.APPCLIENT_DELETE));
            d(remoteClearError(RemoteScope.APPCLIENT_DELETE));
        },
        method: "DELETE",
        pathMapper: (s, ctx) => `/apis/${ctx.apiId}/appclient/${ctx.id}`,
        resMapper: _r => ({}),
        onSuccess: (d, _s, ctx) => {
            d(Mary.utils.modalClose(ctx.modalID));
            d(remoteTrigger(RemoteScope.APPCLIENT_RESULTS, {
                apiId: ctx.apiId,
            }));
        },
    },
    [RemoteScope.CERTIFICATE_RESULTS]: {
        scope: RemoteScope.CERTIFICATE_RESULTS,
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.CERTIFICATE_RESULTS));
            d(remoteClearError(RemoteScope.CERTIFICATE_RESULTS));
        },
        pathMapper: (_s, ctx) => `apis/${ctx.apiId}/subscriptions/${ctx.subscriberId}/certificates`,
        resMapper: mapCertificateRequests,
    },
    [RemoteScope.CERTIFICATE_DETAILS]: {
        scope: RemoteScope.CERTIFICATE_DETAILS,
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.CERTIFICATE_DETAILS));
            d(remoteClearError(RemoteScope.CERTIFICATE_DETAILS));
        },
        pathMapper: (_s, ctx) => `apis/${ctx.apiId}/subscriptions/${ctx.subscriberId}/certificates/${ctx.id}`,
        resMapper: mapCertificateDetailsRequests,
    },
    [RemoteScope.CERTIFICATE_CREATE]: {
        scope: RemoteScope.CERTIFICATE_CREATE,
        method: "POST",
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.CERTIFICATE_CREATE));
            d(remoteClearError(RemoteScope.CERTIFICATE_CREATE));
        },
        pathMapper: (_s, ctx) => `apis/${ctx.apiId}/subscriptions/${ctx.subscriberId}/certificates`,
        resMapper: mapCertificateDetailsRequestsOnCreate,
        bodyMapper: (s, ctx) => {
            const certificate: CertificateRequest = {
                ...ctx.certificate,
            };
            return certificate;
        },
    },
    [RemoteScope.CERTIFICATE_EDIT]: {
        scope: RemoteScope.CERTIFICATE_EDIT,
        method: "PUT",
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.CERTIFICATE_EDIT));
            d(remoteClearError(RemoteScope.CERTIFICATE_EDIT));
        },
        // eslint-disable-next-line max-len
        pathMapper: (_s, ctx) => `apis/${ctx.apiId}/subscriptions/${ctx.subscriberId}/certificates/${ctx.certificateId}`,
        resMapper: mapCertificateDetailsRequests,
        onSuccess: (d, _s, ctx) => {
            d(Mary.utils.modalClose(ctx.modalId));
        },
    },
    [RemoteScope.CERTIFICATE_DELETE]: {
        scope: RemoteScope.CERTIFICATE_DELETE,
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.CERTIFICATE_DELETE));
            d(remoteClearError(RemoteScope.CERTIFICATE_DELETE));
        },
        method: "DELETE",
        // eslint-disable-next-line max-len
        pathMapper: (_s, ctx) => `apis/${ctx.apiId}/subscriptions/${ctx.subscriberId}/certificates/${ctx.id}`,
        resMapper: _r => ({}),
        onSuccess: (d, _s, ctx) => {
            d(Mary.utils.modalClose(ctx.modalID));
            d(remoteTrigger(RemoteScope.CERTIFICATE_RESULTS, {
                apiId: ctx.apiId,
                subscriberId: ctx.subscriberId,
            }));
        },
    },
    [RemoteScope.API_SUBSCRIBER_RESULTS]: {
        scope: RemoteScope.API_SUBSCRIBER_RESULTS,
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.API_SUBSCRIBER_RESULTS));
            d(remoteClearError(RemoteScope.API_SUBSCRIBER_RESULTS));
        },
        pathMapper: (s, ctx) => `apis/${ctx.apiId}/subscriptions`,
        resMapper: mapAPISubscriberRequests,
    },
    [RemoteScope.API_SUBSCRIBER_DETAILS]: {
        scope: RemoteScope.API_SUBSCRIBER_DETAILS,
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.API_SUBSCRIBER_DETAILS));
            d(remoteClearError(RemoteScope.API_SUBSCRIBER_DETAILS));
        },
        pathMapper: (s, ctx) => `apis/${ctx.apiId}/subscriptions/${ctx.subscriberId}`,
        resMapper: mapAPISubscriberDetailsRequests,
        onSuccess: (d, s, ctx) => {
            if (s.prop("remote").prop(RemoteScope.API_SUBSCRIBER_DETAILS)?.allContextLoaded) {
                if (ctx.edit) {
                    d(initUpdateAPISubscriber(ctx.subscriberId));
                }
            }
        },
    },
    [RemoteScope.API_SUBSCRIBER_CREATE]: {
        scope: RemoteScope.API_SUBSCRIBER_CREATE,
        method: "POST",
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.API_SUBSCRIBER_CREATE));
            d(remoteClearError(RemoteScope.API_SUBSCRIBER_CREATE));
        },
        pathMapper: (_s, ctx) => `/apis/${ctx.apiId}/subscriptions`,
        resMapper: mapAPISubscriberDetailsRequests,
        bodyMapper: (s, ctx) => {
            const subscriber: APISubscriberRequest = {
                ...ctx.subscriber,
            };
            return subscriber;
        },
        onSuccess: (d, _s, ctx) => {
            d(remoteTrigger(RemoteScope.API_SUBSCRIBER_RESULTS, {
                apiId: ctx.apiId,
            }));
        },
    },
    [RemoteScope.API_SUBSCRIBER_EDIT]: {
        scope: RemoteScope.API_SUBSCRIBER_EDIT,
        method: "PUT",
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.API_SUBSCRIBER_EDIT));
            d(remoteClearError(RemoteScope.API_SUBSCRIBER_EDIT));
        },
        pathMapper: (_s, ctx) => `/apis/${ctx.apiId}/subscriptions/${ctx.subscriberId}`,
        resMapper: mapAPISubscriberDetailsRequests,
        bodyMapper: (s, ctx) => ctx.subscriber,
        onSuccess: (d, _s, ctx) => {
            d(remoteTrigger(RemoteScope.API_SUBSCRIBER_RESULTS, {
                apiId: ctx.apiId,
            }));
        },
    },
    [RemoteScope.API_SUBSCRIBER_DELETE]: {
        scope: RemoteScope.API_SUBSCRIBER_DELETE,
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.API_SUBSCRIBER_DELETE));
            d(remoteClearError(RemoteScope.API_SUBSCRIBER_DELETE));
        },
        method: "DELETE",
        pathMapper: (s, ctx) => `/apis/${ctx.apiId}/subscriptions/${ctx.id}`,
        resMapper: _r => ({}),
        onSuccess: (d, _s, ctx) => {
            d(Mary.utils.modalClose(ctx.modalID));
            d(remoteTrigger(RemoteScope.API_SUBSCRIBER_RESULTS, {
                apiId: ctx.apiId,
            }));
        },
    },
    [RemoteScope.USAGEPLAN_RESULTS]: {
        scope: RemoteScope.USAGEPLAN_RESULTS,
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.USAGEPLAN_RESULTS));
            d(remoteClearError(RemoteScope.USAGEPLAN_RESULTS));
        },
        pathMapper: (s, ctx) => `apis/${ctx.apiId}/usageplans`,
        resMapper: mapUsagePlanRequests,
    },
    [RemoteScope.USAGEPLAN_DETAILS]: {
        scope: RemoteScope.USAGEPLAN_DETAILS,
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.USAGEPLAN_DETAILS));
            d(remoteClearError(RemoteScope.USAGEPLAN_DETAILS));
        },
        pathMapper: (s, ctx) => `apis/${ctx.apiId}/usageplans/${ctx.usageplanId}`,
        resMapper: mapUsagePlanDetailsRequests,
        onSuccess: (d, _s, ctx) => {
            d(initUpdateUsagePlan(ctx.usageplanId));
        },
    },
    [RemoteScope.USAGEPLAN_CREATE]: {
        scope: RemoteScope.USAGEPLAN_CREATE,
        method: "POST",
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.USAGEPLAN_CREATE));
            d(remoteClearError(RemoteScope.USAGEPLAN_CREATE));
        },
        pathMapper: (s, ctx) => `/apis/${ctx.apiId}/usageplans`,
        resMapper: mapUsagePlanDetailsRequests,
        bodyMapper: (s, ctx) => {
            const usageplan: UsagePlanRequest = {
                ...ctx.usageplan,
            };
            return usageplan;
        },
        onSuccess: (d, _s, ctx) => {
            d(remoteTrigger(RemoteScope.USAGEPLAN_RESULTS, {
                apiId: ctx.apiId,
            }));
        },
    },
    [RemoteScope.USAGEPLAN_EDIT]: {
        scope: RemoteScope.USAGEPLAN_EDIT,
        method: "PUT",
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.USAGEPLAN_EDIT));
            d(remoteClearError(RemoteScope.USAGEPLAN_EDIT));
        },
        pathMapper: (s, ctx) => `/apis/${ctx.apiId}/usageplans/${ctx.usageplanId}`,
        resMapper: mapUsagePlanDetailsRequests,
        bodyMapper: (s, ctx) => ctx.usageplan,
        onSuccess: (d, _s, ctx) => {
            d(remoteTrigger(RemoteScope.USAGEPLAN_RESULTS, {
                apiId: ctx.apiId,
            }));
        },
    },
    [RemoteScope.USAGEPLAN_DELETE]: {
        scope: RemoteScope.USAGEPLAN_DELETE,
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.USAGEPLAN_DELETE));
            d(remoteClearError(RemoteScope.USAGEPLAN_DELETE));
        },
        method: "DELETE",
        pathMapper: (s, ctx) => `/apis/${ctx.apiId}/usageplans/${ctx.id}`,
        resMapper: _r => ({}),
        onSuccess: (d, _s, ctx) => {
            d(Mary.utils.modalClose(ctx.modalID));
            d(remoteTrigger(RemoteScope.USAGEPLAN_RESULTS, {
                apiId: ctx.apiId,
            }));
        },
    },
    [RemoteScope.GENERIC_CONSUMER_RESULTS]: {
        scope: RemoteScope.GENERIC_CONSUMER_RESULTS,
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.GENERIC_CONSUMER_RESULTS));
            d(remoteClearError(RemoteScope.GENERIC_CONSUMER_RESULTS));
        },
        pathMapper: (_s, _ctx) => "/consumers",
        resMapper: mapConsumerRequests,
    },
    [RemoteScope.CONSUMER_RESULTS]: {
        scope: RemoteScope.CONSUMER_RESULTS,
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.CONSUMER_RESULTS));
            d(remoteClearError(RemoteScope.CONSUMER_RESULTS));
        },
        pathMapper: (s, ctx) => `/organizations/${ctx.orgId}/consumers`,
        resMapper: mapConsumerRequests,
        onSuccess: (_d, _s, ctx) => {
            if(ctx.onSuccess) {
                ctx.onSuccess();
            }
        },
    },
    [RemoteScope.CONSUMER_DETAILS]: {
        scope: RemoteScope.CONSUMER_DETAILS,
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.CONSUMER_DETAILS));
            d(remoteClearError(RemoteScope.CONSUMER_DETAILS));
        },
        pathMapper: (s, ctx) => `/organizations/${ctx.orgId}/consumers/${ctx.id}`,
        resMapper: mapConsumerDetailsRequests,
        onSuccess: (d, _s, _ctx) => {
            d(initUpdateConsumer());
        },
    },
    [RemoteScope.CONSUMER_CREATE]: {
        scope: RemoteScope.CONSUMER_CREATE,
        method: "POST",
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.CONSUMER_CREATE));
            d(remoteClearError(RemoteScope.CONSUMER_CREATE));
        },
        pathMapper: (_s, ctx) => `/organizations/${ctx.orgId}/consumers`,
        resMapper: mapConsumerDetailsRequests,
        bodyMapper: (s, ctx) => {
            const consumer: ConsumerRequest = {
                ...ctx.consumer,
            };
            return consumer;
        },
        onSuccess: (d, _s, ctx) => {
            d(remoteTrigger(RemoteScope.CONSUMER_RESULTS, {
                orgId: ctx.orgId,
            }));
        },
    },
    [RemoteScope.CONSUMER_EDIT]: {
        scope: RemoteScope.CONSUMER_EDIT,
        method: "PUT",
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.CONSUMER_EDIT));
            d(remoteClearError(RemoteScope.CONSUMER_EDIT));
        },
        pathMapper: (s, ctx) => `/organizations/${ctx.orgId}/consumers/${ctx.consumerId}`,
        resMapper: mapConsumerDetailsRequests,
        bodyMapper: (s, ctx) => ctx.consumer,
        onSuccess: (d, _s, ctx) => {
            d(remoteTrigger(RemoteScope.CONSUMER_RESULTS, {
                orgId: ctx.orgId,
            }));
        },
    },
    [RemoteScope.CONSUMER_DELETE]: {
        scope: RemoteScope.CONSUMER_DELETE,
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.CONSUMER_DELETE));
            d(remoteClearError(RemoteScope.CONSUMER_DELETE));
        },
        method: "DELETE",
        pathMapper: (_s, ctx) => `/organizations/${ctx.orgId}/consumers/${ctx.id}`,
        resMapper: _r => ({}),
        onSuccess: (d, _s, ctx) => {
            d(Mary.utils.modalClose(ctx.modalID));
            d(remoteTrigger(RemoteScope.CONSUMER_RESULTS, {
                orgId: ctx.orgId,
            }));
        },
    },
    [RemoteScope.CONSUMER_SUBSCRIPTIONS]: {
        scope: RemoteScope.CONSUMER_SUBSCRIPTIONS,
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.CONSUMER_SUBSCRIPTIONS));
            d(remoteClearError(RemoteScope.CONSUMER_SUBSCRIPTIONS));
        },
        pathMapper: (s, ctx) => `/organizations/${ctx.orgId}/consumers/${ctx.consumerId}/subscriptions`,
        resMapper: mapAPISubscriberRequests,
    },
    [RemoteScope.CONSUMER_OVERVIEW_RESULTS]: {
        scope: RemoteScope.CONSUMER_OVERVIEW_RESULTS,
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.CONSUMER_OVERVIEW_RESULTS));
            d(remoteClearError(RemoteScope.CONSUMER_OVERVIEW_RESULTS));
        },
        pathMapper: () => "/consumers/details",
        resMapper: mapConsumerOverviewDetailsRequests,
    },
    [RemoteScope.ORGANISATION_RESULTS]: {
        scope: RemoteScope.ORGANISATION_RESULTS,
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.ORGANISATION_RESULTS));
            d(remoteClearError(RemoteScope.ORGANISATION_RESULTS));
        },
        pathMapper: () => "/organizations",
        resMapper: mapOrganisationRequests,
    },
    [RemoteScope.ORGANISATION_DETAILS]: {
        scope: RemoteScope.ORGANISATION_DETAILS,
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.ORGANISATION_DETAILS));
            d(remoteClearError(RemoteScope.ORGANISATION_DETAILS));
        },
        pathMapper: (s, ctx) => `/organizations/${ctx.id}`,
        resMapper: mapOrganisationDetailsRequests,
        onSuccess: (d, _s, _ctx) => {
            d(initUpdateOrg());
        },
    },
    [RemoteScope.ORGANISATION_CREATE]: {
        scope: RemoteScope.ORGANISATION_CREATE,
        method: "POST",
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.ORGANISATION_CREATE));
            d(remoteClearError(RemoteScope.ORGANISATION_CREATE));
        },
        pathMapper: (_s, _ctx) => "/organizations",
        resMapper: mapOrganisationDetailsRequests,
        bodyMapper: (s, ctx) => {
            const organisation: OrganisationRequest = {
                ...ctx.org,
            };
            return organisation;
        },
        onSuccess: (d, _s, _ctx) => {
            d(remoteTrigger(RemoteScope.ORGANISATION_RESULTS, undefined));
        },
    },
    [RemoteScope.ORGANISATION_EDIT]: {
        scope: RemoteScope.ORGANISATION_EDIT,
        method: "PUT",
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.ORGANISATION_EDIT));
            d(remoteClearError(RemoteScope.ORGANISATION_EDIT));
        },
        pathMapper: (s, ctx) => `/organizations/${ctx.orgId}`,
        resMapper: mapOrganisationDetailsRequests,
        bodyMapper: (s, ctx) => ctx.org,
        onSuccess: (d, _s, _ctx) => {
            d(remoteTrigger(RemoteScope.ORGANISATION_RESULTS, undefined));
        },
    },
    [RemoteScope.ORGANISATION_DELETE]: {
        scope: RemoteScope.ORGANISATION_DELETE,
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.ORGANISATION_DELETE));
            d(remoteClearError(RemoteScope.ORGANISATION_DELETE));
        },
        method: "DELETE",
        pathMapper: (_s, ctx) => `/organizations/${ctx.id}`,
        resMapper: _r => ({}),
        onSuccess: (d, _s, ctx) => {
            d(Mary.utils.modalClose(ctx.modalID));
            d(remoteTrigger(RemoteScope.ORGANISATION_RESULTS, undefined));
        },
    },
};
