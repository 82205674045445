import { Action } from "redux";
import { ActionTypeKeys } from "../ActionTypes";
import { ReducerFn } from "../ReduxState";
import { RemoteScope } from "../RemoteTypes";
import { APISubscriberRequest } from "./Types";
import { Mary } from "@vwpfs/vwpfs-mary-react-comp-lib";

/**
 *
 */
export type InitUpdateAPISubscriberAction = Readonly<
Action<ActionTypeKeys.INIT_UPDATE_APISUBSCRIBER> & {
    id: string;
}
>;

/**
 *
 *
 */
export const initUpdateAPISubscriber =
    (id: string): InitUpdateAPISubscriberAction => ({
        type: ActionTypeKeys.INIT_UPDATE_APISUBSCRIBER,
        id: id,
    });

/**
 *
 * @param s
 * @param a
 */
export const initUpdateAPISubscriberReducer:
ReducerFn<InitUpdateAPISubscriberAction> =
    (s, _a) => {
        const subsriberDetails = s.prop("remote").prop(RemoteScope.API_SUBSCRIBER_DETAILS);
        return (
            s.setProp(
                "apiSubscriberRequest",
                s.prop("apiSubscriberRequest").update(() => subsriberDetails?.content
                    ? {
                        usagePlanId: subsriberDetails?.content.usagePlanId,
                    }
                    : {}),
            )
        );
    };

/**
 *
 */
export type UpdateAPISubscriberAction = Readonly<
Action<ActionTypeKeys.UPDATE_APISUBSCRIBER> & {
    subscriber?: Partial<APISubscriberRequest>;
}
>;

/**
 *
 *
 */
export const updateAPISubscriber =
    (subscriber: APISubscriberRequest): UpdateAPISubscriberAction => ({
        type: ActionTypeKeys.UPDATE_APISUBSCRIBER,
        subscriber: subscriber,
    });

/**
 *
 * @param s
 * @param a
 */
export const updateAPISubscriberReducer:
ReducerFn<UpdateAPISubscriberAction> =
    (s, a) => (
        s.setProp(
            "apiSubscriberRequest",
            s.prop("apiSubscriberRequest").updateWith(
                a.subscriber ?? {},
            ),
        )
    );

/**
 *
 */
export type ClearAPISubscriberAction = Readonly<
Action<ActionTypeKeys.CLEAR_APISUBSCRIBER>
>;

/**
 *
 * @param keys
 */
export const clearAPISubscriber:
() => ClearAPISubscriberAction =
    () => ({
        type: ActionTypeKeys.CLEAR_APISUBSCRIBER,
    });

/**
 *
 */
export const clearAPISubscriberReducer:
ReducerFn<ClearAPISubscriberAction> =
    (s, _a) => (
        s.setProp(
            "apiSubscriberRequest",
            Mary.utils.State.create<APISubscriberRequest>(() => ({})),
        )
    );

export type UpdateSubscriberUsageAction = Readonly<
Action<ActionTypeKeys.UPDATE_APISUBSCRIBER_USAGE> & {
    usageplanId?: string;
}
>;

/**
 *
 *
 */
export const updateSubscriberUsage =
    (usageplanId?: string): UpdateSubscriberUsageAction => ({
        type: ActionTypeKeys.UPDATE_APISUBSCRIBER_USAGE,
        usageplanId: usageplanId,
    });

/**
 *
 * @param s
 * @param a
 */
export const updateSubscriberUsageReducer:
ReducerFn<UpdateSubscriberUsageAction> =
    (s, a) => (
        s.setProp(
            "apiSubscriberRequest",
            s.prop("apiSubscriberRequest").setProp("usagePlanId", a.usageplanId ?? undefined),
        )
    );


/**
 *
 */
export type UpdateConsumerOnOrgChangeAction = Readonly<
Action<ActionTypeKeys.UPDATE_CONSUMER_ON_ORG_CHANGE> & {
}
>;

/**
 *
 *
 */
export const updateConsumerOnOrgChange =
    (): UpdateConsumerOnOrgChangeAction => ({
        type: ActionTypeKeys.UPDATE_CONSUMER_ON_ORG_CHANGE,
    });

/**
 *
 * @param s
 * @param a
 */
export const updateConsumerOnOrgChangeReducer:
ReducerFn<UpdateConsumerOnOrgChangeAction> =
    (s, _a) => {
        const selectedConsumerId = s.prop("apiSubscriberRequest").map(e => ({
            ...e,
        })).consumerId;
        const consumersPerSelectedOrg = s.prop("remote")
            .prop(RemoteScope.CONSUMER_RESULTS)?.content.find(consumer => consumer.id === selectedConsumerId);
        return s.setProp(
            "apiSubscriberRequest",
            s.prop("apiSubscriberRequest").updateWith({
                consumerName: consumersPerSelectedOrg?.name ?? "",
                consumerId: consumersPerSelectedOrg?.id ?? "",
            }),
        );
    };
