/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { AxiosResponse } from "axios";
import { Organisation, OrganisationDetails, Organisations } from "./Types";

export const mapOrganisationRequests =
    (resp: AxiosResponse): Organisations => {
        const requests = (resp.data.content ? resp.data.content as Organisation[]
            : resp.data as Organisation[]);

        const organisations = {
            content: requests,
        };

        return organisations;
    };

/**
 *
 * @param resp
 */
export const mapOrganisationDetailsRequests =
 (resp: AxiosResponse): OrganisationDetails => {
     const request = (resp.data.content ? resp.data.content as Organisation
         : resp.data as Organisation);

     const organisation = {
         content: request,
     };

     return organisation;
 };
