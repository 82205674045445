import { Action } from "redux";
import { ActionTypeKeys } from "../ActionTypes";
import { ReducerFn } from "../ReduxState";
import { RemoteScope } from "../RemoteTypes";
import { APIRequest, LoggingLevel } from "./Types";
import { Mary } from "@vwpfs/vwpfs-mary-react-comp-lib";

/**
 *
 */
export type InitUpdateAPIAction = Readonly<
Action<ActionTypeKeys.INIT_UPDATE_API> & {
}
>;

/**
 *
 *
 */
export const initUpdateAPI =
    (): InitUpdateAPIAction => ({
        type: ActionTypeKeys.INIT_UPDATE_API,
    });

/**
 *
 * @param s
 * @param a
 */
export const initUpdateAPIReducer:
ReducerFn<InitUpdateAPIAction> =
    (s, _a) => {
        const apiDetails = s.prop("remote").prop(RemoteScope.API_DETAILS);
        return (
            s.setProp(
                "apiRequest",
                s.prop("apiRequest").update(() => apiDetails?.content ? {
                    loggingLevel: apiDetails?.content?.logging?.level === LoggingLevel.OFF || !apiDetails
                        ? LoggingLevel.ERROR : apiDetails?.content?.logging?.level,
                    dataTraceEnabled: apiDetails?.content?.logging?.dataLoggingEnabled,
                    metricsEnabled: apiDetails?.content?.metricsEnabled,
                } : {}),
            )
        );
    };

/**
 *
 */
export type UpdateAPIAction = Readonly<
Action<ActionTypeKeys.UPDATE_API> & {
    api?: Partial<APIRequest>;
}
>;

/**
 *
 *
 */
export const updateAPI =
    (api: APIRequest): UpdateAPIAction => ({
        type: ActionTypeKeys.UPDATE_API,
        api: api,
    });

/**
 *
 * @param s
 * @param a
 */
export const updateAPIReducer:
ReducerFn<UpdateAPIAction> =
    (s, a) => (
        s.setProp(
            "apiRequest",
            s.prop("apiRequest").updateWith(
                a.api ?? {},
            ),
        )
    );

/**
 *
 */
export type ClearAPIAction = Readonly<
Action<ActionTypeKeys.CLEAR_API>
>;

/**
 *
 * @param keys
 */
export const clearAPI:
() => ClearAPIAction =
    () => ({
        type: ActionTypeKeys.CLEAR_API,
    });

/**
 *
 */
export const clearAPIReducer:
ReducerFn<ClearAPIAction> =
    (s, _a) => (
        s.setProp(
            "apiRequest",
            Mary.utils.State.create<APIRequest>(() => ({})),
        )
    );
