/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { AxiosResponse } from "axios";
import { AppClient, AppClientDetails, AppClientDetail, AppClients } from "./Types";


export const mapAppClientRequests =
    (resp: AxiosResponse): AppClients => {
        const requests = (resp.data.content ? resp.data.content as AppClient[]
            : resp.data as AppClient[]);

        const appClients = {
            content: requests,
        };

        return appClients;
    };

export const mapAppClientDetailsRequests =
 (resp: AxiosResponse): AppClientDetails => {
     const request = (resp.data.content ? resp.data.content as AppClientDetail
         : resp.data as AppClientDetail);

     const appClient = {
         content: request,
     };

     return appClient;
 };
