/* eslint-disable max-len */
import { OrderedSet } from "immutable";
import * as React from "react";
import { connect } from "react-redux";
import { DispatchFunc } from "../../../store/ActionTypes";
import { toggleSidebar } from "../../../store/AppActions";
import { PageDisplay, Pages, UserRolesDisplays } from "../../../store/AppDisplays";
import { APIManagerRole, UserInfo } from "../../../store/AppTypes";
import { ReduxState } from "../../../store/ReduxState";
import backgroundImage from "../../assets/images/sidebar-bg.png";
import { DashboardMenu } from "./00-blocks/DashboardMenu";
import { Mary } from "@vwpfs/vwpfs-mary-react-comp-lib";
import { ReleaseVersion } from "../05-atoms/ReleaseVersion";
import { KeycloakLoggedIn, KeycloakNotLoggedIn } from "../05-atoms/keycloakLoggedIn";
import { LogoutBtn } from "../05-atoms/keycloaklogoutbtn";

interface OwnProps {
    noRights?: boolean;
}

/**
 *
 */
interface StateProps {
    user?: UserInfo;
    availablePages: OrderedSet<Pages>;
    sidebarCollapsed: boolean;
}

/**
 *
 */
interface DispatchProps {
    toggleSidebar: (force?: boolean) => void;
    setFocus: () => void;
    closeModal: (id: string) => void;
}

type Props = OwnProps & StateProps & DispatchProps;

/**
 *
 * @param s
 */
const mapStateToProps = (s: ReduxState): StateProps => ({
    user: s.prop("user").isPresent() ? s.prop("user").get() : undefined,
    availablePages: s.prop("pages").prop("pages"),
    sidebarCollapsed: s.prop("sidebarCollapsed"),
});


/**
 *
 */
export const mapDispatchToProps = (dispatch: DispatchFunc): DispatchProps => ({
    toggleSidebar: (force?: boolean) => dispatch(toggleSidebar(force)),
    setFocus: () => dispatch(Mary.utils.flexSetFocus(Mary.base.FlexWrapper.FlexWrapperFocus.SIDEBAR_INACTIVE)),
    closeModal: (id: string) => {
        dispatch(Mary.utils.modalClose(id));
    },
});

/**
 *
 * @param _props
 */
class SideBarComp extends React.Component<Props, {}> {

    public constructor(props: Props) {
        super(props);
        this.updateCollapseState = this.updateCollapseState.bind(this);
        this.setFocus = this.setFocus.bind(this);
        this.updateCollapseStateWithIcon = this.updateCollapseStateWithIcon.bind(this);
    }

    public render() {
        return (
            <Mary.base.BaseConsumer>{({ getTheme }) =>
                (
                    <Mary.utils.SwipeBlock detectSwipeDir={(value: string) => this.updateCollapseState(value === "Left")}>
                        <Mary.base.flex.FlexSidebar isCollapsed={this.props.sidebarCollapsed}>
                            <Mary.utils.BreakpointConsumer>
                                {(value) => (
                                    <>
                                        <Mary.base.flex.FlexRow theme={{ palette: getTheme() === Mary.theme.Themes.DUTCHLEASE ? Mary.theme.ThemePalette.CONTRAST_PRIMARY : Mary.theme.ThemePalette.BRAND_PRIMARY }}>
                                            <Mary.base.flex.FlexColumn hasScrollable className="v-sidebar" style={{ backgroundImage: `url(${backgroundImage})` }}>
                                                { (this.props.user || !value.breakpointAtSM) &&
                                                    <Mary.base.flex.FlexRow
                                                        isFixed
                                                        className="v-sidebar__userinfo"
                                                    >
                                                        <Mary.organisms.NavbarStackContent>
                                                            {!this.props.sidebarCollapsed || !value.breakpointAtSM ?
                                                                <>
                                                                    <KeycloakLoggedIn>
                                                                        {this.props.user && this.props.user.token ? (
                                                                            <Mary.molecules.UserInfo
                                                                                name={this.props.user?.userName ?? "Loggedin as,"}
                                                                                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                                                                                role={UserRolesDisplays[this.props.user?.userRole ?? APIManagerRole.NONE]}
                                                                            />) : (
                                                                            <Mary.molecules.UserInfo
                                                                                name={
                                                                                    Mary.utils.getText(
                                                                                        "app.userNotAuthenticated",
                                                                                        "Can't authenticate user!",
                                                                                    ) ?? ""
                                                                                }
                                                                            />
                                                                        )}
                                                                    </KeycloakLoggedIn>
                                                                    <KeycloakNotLoggedIn>
                                                                        <Mary.molecules.UserInfo
                                                                            name={"Not loggedin"}
                                                                        />
                                                                    </KeycloakNotLoggedIn>
                                                                </>
                                                                : <></>}
                                                        </Mary.organisms.NavbarStackContent>
                                                        <Mary.organisms.NavbarStackRight isCollapsed={this.props.sidebarCollapsed && value.breakpointAtSM}>
                                                            {
                                                                <>
                                                                    <>
                                                                        {(this.props.sidebarCollapsed && value.breakpointAtSM) &&
                                                                            <Mary.organisms.NavbarIcon>
                                                                                <Mary.atoms.button.Button
                                                                                    className={"scl-o-navbar__icon"}
                                                                                    link={{ href: PageDisplay[Pages.HOME].path }}
                                                                                    icon="home" />
                                                                            </Mary.organisms.NavbarIcon>
                                                                        }
                                                                        <>
                                                                            <KeycloakLoggedIn>
                                                                                {value.breakpointAtSM && (
                                                                                    <Mary.organisms.NavbarIcon>
                                                                                        <LogoutBtn />
                                                                                    </Mary.organisms.NavbarIcon>
                                                                                )}
                                                                            </KeycloakLoggedIn>
                                                                        </>
                                                                    </>
                                                                    {!value.breakpointAtSM &&
                                                                    <Mary.organisms.NavbarIcon>
                                                                        <Mary.atoms.button.Button
                                                                            className={"scl-o-navbar__icon"}
                                                                            link={{
                                                                                onClick: this.setFocus(),
                                                                            }}
                                                                            icon={Mary.atoms.IconNames.TIMES}
                                                                        />
                                                                    </Mary.organisms.NavbarIcon>
                                                                    }
                                                                </>
                                                            }
                                                        </Mary.organisms.NavbarStackRight>
                                                    </Mary.base.flex.FlexRow>
                                                }
                                                <Mary.base.flex.FlexRow isScrollable className="v-sidebar__menu">
                                                    <Mary.base.flex.FlexColumn className="v-sidebar__menu-content">
                                                        {(!this.props.noRights &&
                                                            this.props.user &&
                                                            this.props.user.token &&this.props.availablePages && (!this.props.sidebarCollapsed || !value.breakpointAtSM)) &&
                                                        <DashboardMenu
                                                            tabs={this.props.availablePages}
                                                        />}
                                                        <Mary.base.flex.FlexRow className={(this.props.sidebarCollapsed && value.breakpointAtSM) ? "v-sidebar__version-collapsed" : "v-sidebar__version"} isCollapsed={this.props.sidebarCollapsed || !value.breakpointAtSM}>
                                                            {(!this.props.sidebarCollapsed || !value.breakpointAtSM) &&
                                                            <>
                                                                <Mary.base.Div className="v-sidebar__title">
                                                                    {Mary.utils.getText("app.app-title", "API Manager")}
                                                                </Mary.base.Div>
                                                                <Mary.base.Logo theme={getTheme()} variant={Mary.theme.ThemeVariants.CONTRAST} className="v-sidebar__logo--vwpfs" />
                                                                <Mary.base.Logo theme={Mary.theme.Themes.CLAUDE} variant={Mary.theme.ThemeVariants.CONTRAST} className="v-sidebar__logo--claude" />
                                                            </>
                                                            }
                                                            <ReleaseVersion
                                                                isCollapsed={this.props.sidebarCollapsed}
                                                                apiLabel={"API spec"}
                                                            />
                                                        </Mary.base.flex.FlexRow>
                                                    </Mary.base.flex.FlexColumn>
                                                </Mary.base.flex.FlexRow>
                                            </Mary.base.flex.FlexColumn>
                                        </Mary.base.flex.FlexRow>
                                        {(value.breakpointAtSM) && (
                                            <Mary.atoms.button.Button
                                                link={{ onClick: this.updateCollapseStateWithIcon(!this.props.sidebarCollapsed) }}
                                                theme={{
                                                    palette: Mary.theme.ThemePalette.BRAND_ACCENT,
                                                    shadow: Mary.theme.ThemeShadowSizes.TINY,
                                                }}
                                                className="m-info-card__icon"
                                                icon={this.props.sidebarCollapsed ? "chevron-right" : "chevron-left"}
                                                style={{ marginRight: "-35px", top: "50%" }}
                                            />
                                        )}
                                    </>
                                )}
                            </Mary.utils.BreakpointConsumer>
                        </Mary.base.flex.FlexSidebar>
                    </Mary.utils.SwipeBlock>
                )}
            </Mary.base.BaseConsumer>
        );
    }

    private updateCollapseStateWithIcon(collapse: boolean) {
        return () => this.updateCollapseState(collapse);
    }

    private updateCollapseState(collapse: boolean) {
        this.props.toggleSidebar(collapse);
    }

    private setFocus() {
        return () => this.props.setFocus();
    }
}

// const SideBarWrapper: React.FunctionComponent = props => (<SideBarComp {...props as Props} />);

/**
 *
 */
export const SideBar = connect(
    mapStateToProps,
    mapDispatchToProps,
)(SideBarComp);
