/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { AxiosResponse } from "axios";
import { Consumer, ConsumerDetails, ConsumerOverview, ConsumerOverviewDetails, Consumers } from "./Types";

export const mapConsumerRequests =
    (resp: AxiosResponse): Consumers => {
        const response = (resp.data.content ? resp.data.content as Consumer[]
            : resp.data as Consumer[]);

        const consumers = {
            content: response,
        };

        return consumers;
    };

/**
 *
 * @param resp
 */
export const mapConsumerDetailsRequests =
 (resp: AxiosResponse): ConsumerDetails => {
     const response = (resp.data.content ? resp.data.content as Consumer
         : resp.data as Consumer);

     const consumer = {
         content: response,
     };

     return consumer;
 };

/**
 *
 * @param resp
 */
export const mapConsumerOverviewDetailsRequests =
(resp: AxiosResponse): ConsumerOverviewDetails => {
    const response = (resp.data.content ? resp.data.content as ConsumerOverview[]
        : resp.data as ConsumerOverview[]);

    const consumerOverview = {
        content: response,
    };

    return consumerOverview;
};
