import { Action } from "redux";
import { ActionTypeKeys } from "../ActionTypes";
import { ReducerFn } from "../ReduxState";
import { AppClientRequest } from "./Types";
import { RemoteScope } from "../RemoteTypes";
import { Mary } from "@vwpfs/vwpfs-mary-react-comp-lib";

/**
 *
 */
export type InitUpdateAppClientAction = Readonly<
Action<ActionTypeKeys.INIT_UPDATE_APPCLIENT> & {}
>;

/**
 *
 *
 */
export const initUpdateAppClient =
    (): InitUpdateAppClientAction => ({
        type: ActionTypeKeys.INIT_UPDATE_APPCLIENT,
    });

/**
 *
 * @param s
 */
export const initUpdateAppClientReducer:
ReducerFn<InitUpdateAppClientAction> =
    (s, _a) => {
        const appClientDetails = s.prop("remote").prop(RemoteScope.APPCLIENT_DETAILS);
        return (
            s.setProp(
                "appClientRequest",
                s.prop("appClientRequest").update(() => appClientDetails?.content ?? {}),
            )
        );
    };

/**
 *
 */
export type UpdateAppClientAction = Readonly<
Action<ActionTypeKeys.UPDATE_APPCLIENT> & {
    appClient?: Partial<AppClientRequest>;
}
>;

/**
 *
 *
 */
export const updateAppClient =
    (appClient: AppClientRequest): UpdateAppClientAction => ({
        type: ActionTypeKeys.UPDATE_APPCLIENT,
        appClient: appClient,
    });

/**
 *
 * @param s
 * @param a
 */
export const updateAppClientReducer:
ReducerFn<UpdateAppClientAction> =
    (s, a) => (
        s.setProp(
            "appClientRequest",
            s.prop("appClientRequest").updateWith(
                a.appClient ?? {},
            ),
        )
    );

/**
 *
 */
export type ClearAppClientAction = Readonly<
Action<ActionTypeKeys.CLEAR_APPCLIENT>
>;

/**
 *
 * @param keys
 */
export const clearAppClient:
() => ClearAppClientAction =
    () => ({
        type: ActionTypeKeys.CLEAR_APPCLIENT,
    });

/**
 *
 */
export const clearAppClientReducer:
ReducerFn<ClearAppClientAction> =
    (s, _a) => (
        s.setProp(
            "appClientRequest",
            Mary.utils.State.create<AppClientRequest>(() => ({})),
        )
    );
