/* eslint-disable max-len */
export const I18nAppTranslationsNL = {
    "app.app-title": "API Manager",
    "app.general": "Algemeen",
    "app.yes": "Ja",
    "app.no": "Nee",
    "app.pdf": "PDF",
    "app.csv": "CSV",
    "app.make-a-choice": "Maak een keuze",
    "app.view-pdf": "Bekijk PDF",
    "app.view-table-pdf-appendix": "Bijlage",
    "app.view-table-pdf-footer": "MAN Financial Services is een handelsnaam van Volkswagen Pon Financial Services B.V.",
    "app.view-table-pdf-postaddress": "Postadres",
    "app.validation.has-value-message": "{{label}} ingevuld.",
    "app.06-molecules.downloadpdfbutton.no-download": "Geen download beschikbaar",
    "app.go-to-link": "Ga naar link",
    "app.home.title": "Welkom,",
    "app.home.description": "U kunt alles doen wat te maken heeft met het beheer van de API's",
    "app.remote-error": "Claude kan niet aan uw aanvraag voldoen.",
    "app.09-views.00-blocks.auth-download-successr": "Het bestand is gedownload.",
    "app.fatal-remote-error": "Zelfs Claude weet niet wat er mis is!",
    "app.remote-init-error": "Geen API beschikbaar.",
    "app.remote-init-message":
            "Claude kan geen verbinding met de API maken.",
    "app.notification": "Notificatie",
    "app.warning": "Waarschuwing",
    "app.store.getremoteerrormessage.status": "Status:",
    "app.store.getremoteerrormessage.error": "Foutmelding:",
    "app.not-authorized.title": "Niet geautoriseerd.",
    "app.not-authorized.message":
            "U bent niet geautoriseerd, hierdoor kunnen wij geen verbinding met de API maken, ververs de pagina!",
};
