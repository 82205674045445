/* eslint-disable */
import { Div } from "@vwpfs/vwpfs-mary-react-comp-lib/dist/components/core/03-base";
import { Badge } from "@vwpfs/vwpfs-mary-react-comp-lib/dist/components/core/05-atoms/Badge";
import { ThemePalette, ThemeShadowSizes } from "@vwpfs/vwpfs-mary-react-comp-lib/dist/theme";
import React from "react";


/**
 *
 */
interface Props {
    appVersion?: string;
    apiVersion?: string;
    isCollapsed?: boolean;
    apiLabel?: string;
    withMaryVersion?: boolean;
}

/**
 *
 * @param props
 */
export const ReleaseVersion: React.FC<Props> = (props) => {

    const packageForReleaseVersion = require("../../../../package.json");
    return(
        <Div className="scl-m-release-version">
            <Badge
                theme={{
                    palette: ThemePalette.BRAND_ACCENT,
                    shadow: ThemeShadowSizes.TINY,
                }}
            >
                {props.appVersion ?? packageForReleaseVersion.version}
            </Badge>
            { !!props.apiVersion && !props.isCollapsed &&
                <>
                &nbsp;
                    <Badge
                        theme={{
                            palette: ThemePalette.CONTRAST_TERTIARY,
                            shadow: ThemeShadowSizes.TINY,
                        }}
                    >
                        <span style={{textTransform: "none"}}>{props.apiLabel ? props.apiLabel : "API"}:&nbsp;{props.apiVersion}</span>
                    </Badge>
                </>
            }
            { !!props.withMaryVersion && !props.isCollapsed &&
                <>
                &nbsp;
                    <Badge
                        theme={{
                            palette: ThemePalette.CONTRAST_TERTIARY,
                            shadow: ThemeShadowSizes.TINY,
                        }}
                    >
                        <span style={{textTransform: "none"}}>Mary:&nbsp;{packageForReleaseVersion.version}</span>
                    </Badge>
                </>
            }
        </Div>
    );
};
