/* eslint-disable */
import { Map, OrderedSet } from "immutable";
import React, { Suspense } from "react";
import { connect } from "react-redux";
import {
    Route,
    Switch,
} from "react-router-dom";

import { DispatchFunc } from "../src/store/ActionTypes";
import { ReduxState, RemoteErrors } from "../src/store/ReduxState";
import { PageDisplay, Pages, SubPageDisplay, SubPages } from "./store/AppDisplays";
import { remoteClearError } from "./store/RemoteActions";
import { RemoteErrorType, RemoteScope } from "./store/RemoteTypes";
import { Mary } from "@vwpfs/vwpfs-mary-react-comp-lib";
import { NoRole } from "./App/components/09-views/02-pages/NoRole";

/**
 *
 */
interface OwnProps {
}

/**
 *
 */
interface DispatchProps {
    handleRemoteError: (scope: RemoteScope, error: RemoteErrors) => void;
}

/**
 *
 */
interface StateProps {
    remoteErrors: Map<RemoteScope, RemoteErrors>;
    availablePages: OrderedSet<Pages>;
    isAuthenticated: boolean;
}

const handleRemoteErrorModal = (id: string, onClose: () => void, error: RemoteErrors) => {
    const modal = (
        <Mary.views.modals.ModalNotification
            refreshButton={error.type === RemoteErrorType.AUTHORIZATION}
            id={id}
            title={error.type === RemoteErrorType.VALIDATION
                ? Mary.utils.getText("app.notification", "Notification")
                : Mary.utils.getText("app.warning", "Warning")}
            onCloseModal={onClose}
            theme={{
                paletteState: error.type !== RemoteErrorType.VALIDATION
                    ? error.type === RemoteErrorType.UNKNOWN_ERROR
                || error.type === RemoteErrorType.DEFAULT
                        ? Mary.theme. ThemePaletteState.DANGER : Mary.theme. ThemePaletteState.WARNING
                    : undefined,
                palette: error.type === RemoteErrorType.VALIDATION ? Mary.theme.ThemePalette.CONTRAST_TERTIARY
                    : undefined,
            }}
        >
            <React.Fragment>
                <Mary.atoms.Blockquote
                    style={{
                        maxHeight: "200px",
                        overflowY: "scroll",
                        wordWrap: "break-word",
                        whiteSpace: "pre-line" }}
                >
                    <Mary.organisms.RTE>
                        <h5>
                            {error.type !== RemoteErrorType.UNKNOWN_ERROR ?
                                Mary.utils.getText("app.remote-error", "Claude can't continue with your request.") :
                                Mary.utils.getText("app.fatal-remote-error", "Even Claude doesn't know what went wrong here!")
                            }
                        </h5>
                        {error.message}
                    </Mary.organisms.RTE>
                </Mary.atoms.Blockquote>
            </React.Fragment>
        </Mary.views.modals.ModalNotification>
    );
    return () => modal;
};

const mapDispatchToProps = (dispatch: DispatchFunc): DispatchProps => ({
    handleRemoteError: (scope, error) => dispatch(
        Mary.utils.modalShow("handleremoteerror", handleRemoteErrorModal("handleremoteerror",() => {
            dispatch(Mary.utils.modalClose("handleremoteerror"));
            dispatch(remoteClearError(scope));
        }, error))),
});

const mapStateToProps = (s: ReduxState): StateProps => ({
    remoteErrors: s.prop("remoteErrors"),
    availablePages: s.prop("pages").prop("pages"),
    isAuthenticated: !!s.prop("user").isPresent() && !!s.prop("user").get().userRole,
});

/**
 *
 */
type Props = OwnProps & StateProps & DispatchProps;

/**
 * AppComp implements Claude's root component.
 */
class AppComp
    extends React.Component<Props, {}> {

    public constructor(props: Props) {
        super(props);

        this.checkRemoteError = this.checkRemoteError.bind(this);
    }

    public componentDidMount() {
        this.checkRemoteError();
    }

    public componentDidUpdate(prevProps: Props) {
        this.checkRemoteError();
    }

    /**
     *
     */
    public render() {
        return (
            <Suspense fallback={<Mary.views.pages.Loading />}>
                <Switch>
                    {
                        this.props.isAuthenticated && (!this.props.availablePages.isEmpty()) ? (
                            [
                            this.props.availablePages.map((page: Pages, index) => ([
                                <Route
                                    key={"route-" + PageDisplay[page].path + index}
                                    path={PageDisplay[page].path}
                                    component={PageDisplay[page].component}
                                    exact
                                />,

                                    PageDisplay[page].subpages.map((subpage: SubPages, subindex) => (
                                    <Route
                                        key={"sub-route-" + SubPageDisplay[subpage].path + subindex}
                                        path={SubPageDisplay[subpage].path}
                                        component={SubPageDisplay[subpage].component}
                                        exact
                                    />
                                ))]
                            )),
                            <Route
                                key={"fallback-no-route"}
                                render={() => (
                                    <Mary.views.pages.NoMatchingRoute
                                        // eslint-disable-next-line max-len
                                        title={!this.props.availablePages ? "Claude is not initiated yet" : undefined}
                                        description={!this.props.availablePages ?
                                            "It could be that the application is still loading.." : undefined}
                                    />
                                )}
                            />
                            ]
                    ) :  <Route key={"fallback-no-role"} path="*" exact component={NoRole} />
                }
                </Switch>
            </Suspense>
        );
    }

    private checkRemoteError = () => {
        const errorEntry = this.props.remoteErrors.findEntry((val) => !!val);
        if (!!errorEntry) {
            const [scope, reason] = errorEntry;
            this.props.handleRemoteError(scope, reason);
        }
    };
}

/**
 *
 */
const AppWrapper: React.FunctionComponent<OwnProps> = props => (<AppComp {...props as Props} />);

/**
 *
 */
export const App = connect(
    mapStateToProps,
    mapDispatchToProps,
)(AppWrapper);

export const testUtil = {
    AppComp: AppComp,
    handleRemoteErrorModal: handleRemoteErrorModal,
    mapDispatchToProps: mapDispatchToProps,
    mapStateToProps: mapStateToProps,
};
