import Keycloak from "keycloak-js";

export const verifyToken = async (keycloak: Keycloak) => {
    // get the authentication token from local storage if it exists

    if (
        keycloak?.authenticated &&
    // I kinda feel like we don't need to read if from local storage, but for now let's keep it like this
    // We could just directly read it from keycloak.token
    !!localStorage.getItem("token") &&
    localStorage.getItem("token") !== ""
    ) {
        console.log("Claude API call, authenticated with IRIS.");
        if (keycloak.isTokenExpired(50)) {
            console.log("Claude API call, access token is expired.");
            await keycloak.updateToken(50);

            return keycloak.token;
        }
    } else {
        console.warn("Claude API call, not authenticated with IRIS.");
        keycloak
            ?.logout()
            .then(() => {
                console.log("Iris: Logout success");
            })
            .catch(() => {
                console.log("Iris: Logout failed");
            });
    }

    // Read token, in this case directly from keycloak
    // Will basically only be returned if (keycloak.isTokenExpired(50) !== true
    // Since you will be logged out other wise.
    return keycloak.token;
};
