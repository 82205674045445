/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import {
    Action,
    Store,
    applyMiddleware,
    compose,
    createStore,
} from "redux";
import thunk from "redux-thunk";
import { ActionTypeKeys, Config } from "./ActionTypes";
import { clearAPIReducer, initUpdateAPIReducer, updateAPIReducer } from "./API/UpdateType";
import { clearAppClientReducer, initUpdateAppClientReducer, updateAppClientReducer } from "./APIAppClients/CreateType";
import {
    clearCertificateReducer,
    updateCertificateReducer,
} from "./APICertificates/CreateType";
import {
    clearAPISubscriberReducer,
    initUpdateAPISubscriberReducer,
    updateAPISubscriberReducer,
    updateConsumerOnOrgChangeReducer,
    updateSubscriberUsageReducer,
} from "./APISubscribers/CreateType";
import { clearUsagePlanReducer, initUpdateUsagePlanReducer, updateUsagePlanReducer } from "./APIUsagePlans/CreateType";
import { appInitReducer, toggleSidebarReducer, signoutReducer } from "./AppActions";
import { clearConsumerReducer, initUpdateConsumerReducer, updateConsumerReducer } from "./Consumers/CreateType";
import { clearOrgReducer, initUpdateOrgReducer, updateOrgReducer } from "./Organisations/CreateType";
import { ReduxState, ReduxStateInit } from "./ReduxState";
import {
    remoteCancelReducer,
    remoteClearErrorReducer,
    remoteClearResponseReducer,
    remoteErrorReducer,
    remoteResponseReducer,
    remoteTriggerReducer,
} from "./RemoteActions";
import { Mary } from "@vwpfs/vwpfs-mary-react-comp-lib";

// DO NOT TOUCH - WE KNOW WHAT WE ARE DOING, I SWEAR
// https://github.com/palantir/tslint/issues/144#issuecomment-195453810
//
// TODO: the disabled lint may or may not be needed here.

declare let window: any;
const composeEnhancers: any = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

/**
 * Reducer defines the type signature of this reducer.
 */
// type StoreConfig = Readonly<{
//     apiEndpoint: string;
// }>;

const rootReducer =
    Mary.utils.withMaryReducers(
        Mary.utils.Reducers
            .create<ActionTypeKeys |  Mary.utils.MaryActionTypeKeys, ReduxState>()
            .register(
                ActionTypeKeys.APP_INIT,
                appInitReducer as Mary.utils.Reducer<ReduxState, Action<ActionTypeKeys.APP_INIT>>,
            )
            .register(
                ActionTypeKeys.SIGN_OUT,
                signoutReducer,
            )
            .register(
                ActionTypeKeys.REMOTE_TRIGGER,
                remoteTriggerReducer,
            )
            .register(
                ActionTypeKeys.REMOTE_CANCEL,
                remoteCancelReducer,
            )
            .register(
                ActionTypeKeys.REMOTE_RESPONSE,
                remoteResponseReducer,
            )
            .register(
                ActionTypeKeys.REMOTE_CLEAR_RESPONSE,
                remoteClearResponseReducer,
            )
            .register(
                ActionTypeKeys.REMOTE_ERROR,
                remoteErrorReducer,
            )
            .register(
                ActionTypeKeys.REMOTE_CLEAR_ERROR,
                remoteClearErrorReducer,
            )
            .register(
                ActionTypeKeys.UPDATE_CERTIFICATE,
                updateCertificateReducer,
            )
            .register(
                ActionTypeKeys.CLEAR_CERTIFICATE,
                clearCertificateReducer,
            )
            .register(
                ActionTypeKeys.INIT_UPDATE_APPCLIENT,
                initUpdateAppClientReducer,
            )
            .register(
                ActionTypeKeys.UPDATE_APPCLIENT,
                updateAppClientReducer,
            )
            .register(
                ActionTypeKeys.CLEAR_APPCLIENT,
                clearAppClientReducer,
            )
            .register(
                ActionTypeKeys.INIT_UPDATE_API,
                initUpdateAPIReducer as Mary.utils.Reducer<ReduxState, Action<ActionTypeKeys.INIT_UPDATE_API>>,
            )
            .register(
                ActionTypeKeys.UPDATE_API,
                updateAPIReducer,
            )
            .register(
                ActionTypeKeys.CLEAR_API,
                clearAPIReducer,
            )
            .register(
                ActionTypeKeys.INIT_UPDATE_APISUBSCRIBER,
                initUpdateAPISubscriberReducer as Mary.utils.Reducer<ReduxState, Action<ActionTypeKeys.INIT_UPDATE_APISUBSCRIBER>>,
            )
            .register(
                ActionTypeKeys.UPDATE_APISUBSCRIBER,
                updateAPISubscriberReducer,
            )
            .register(
                ActionTypeKeys.CLEAR_APISUBSCRIBER,
                clearAPISubscriberReducer,
            )
            .register(
                ActionTypeKeys.INIT_UPDATE_CONSUMER,
                initUpdateConsumerReducer as Mary.utils.Reducer<ReduxState, Action<ActionTypeKeys.INIT_UPDATE_CONSUMER>>,
            )
            .register(
                ActionTypeKeys.UPDATE_CONSUMER,
                updateConsumerReducer,
            )
            .register(
                ActionTypeKeys.CLEAR_CONSUMER,
                clearConsumerReducer,
            )
            .register(
                ActionTypeKeys.UPDATE_CONSUMER_ON_ORG_CHANGE,
                updateConsumerOnOrgChangeReducer,
            )
            .register(
                ActionTypeKeys.INIT_UPDATE_ORG,
                initUpdateOrgReducer as Mary.utils.Reducer<ReduxState, Action<ActionTypeKeys.INIT_UPDATE_ORG>>,
            )
            .register(
                ActionTypeKeys.UPDATE_ORG,
                updateOrgReducer,
            )
            .register(
                ActionTypeKeys.CLEAR_ORG,
                clearOrgReducer,
            )
            .register(
                ActionTypeKeys.UPDATE_APISUBSCRIBER_USAGE,
                updateSubscriberUsageReducer,
            )
            .register(
                ActionTypeKeys.INIT_UPDATE_USAGEPLAN,
                initUpdateUsagePlanReducer as Mary.utils.Reducer<ReduxState, Action<ActionTypeKeys.INIT_UPDATE_USAGEPLAN>>,
            )
            .register(
                ActionTypeKeys.UPDATE_USAGEPLAN,
                updateUsagePlanReducer,
            )
            .register(
                ActionTypeKeys.CLEAR_USAGEPLAN,
                clearUsagePlanReducer,
            )
            .register(
                ActionTypeKeys.TOGGLE_SIDEBAR,
                toggleSidebarReducer,
            ),
    );

export const createReduxStore: (conf: Config) => Store<ReduxState> =
    conf => createStore<ReduxState, Action<ActionTypeKeys |  Mary.utils.MaryActionTypeKeys>, {}, {}>(
        (state, action) => rootReducer.reduce(state ||  Mary.utils.State.create(ReduxStateInit(conf)), action),
        composeEnhancers(applyMiddleware(thunk)),
    );

