import { Action } from "redux";
import { ActionTypeKeys } from "../ActionTypes";
import { ReducerFn } from "../ReduxState";
import { CertificateRequest } from "./Types";
import { Mary } from "@vwpfs/vwpfs-mary-react-comp-lib";

/**
 *
 */
export type UpdateCertificateAction = Readonly<
Action<ActionTypeKeys.UPDATE_CERTIFICATE> & {
    certificate?: Partial<CertificateRequest>;
}
>;

/**
 *
 *
 */
export const updateCertificate =
    (certificate: CertificateRequest): UpdateCertificateAction => ({
        type: ActionTypeKeys.UPDATE_CERTIFICATE,
        certificate: certificate,
    });

/**
 *
 * @param s
 * @param a
 */
export const updateCertificateReducer:
ReducerFn<UpdateCertificateAction> =
    (s, a) => (
        s.setProp(
            "certificateRequest",
            s.prop("certificateRequest").updateWith(
                a.certificate ?? {},
            ),
        )
    );

/**
 *
 */
export type ClearCertificateAction = Readonly<
Action<ActionTypeKeys.CLEAR_CERTIFICATE>
>;

/**
 *
 * @param keys
 */
export const clearCertificate:
() => ClearCertificateAction =
    () => ({
        type: ActionTypeKeys.CLEAR_CERTIFICATE,
    });

/**
 *
 */
export const clearCertificateReducer:
ReducerFn<ClearCertificateAction> =
    (s, _a) => (
        s.setProp(
            "certificateRequest",
            Mary.utils.State.create<CertificateRequest>(() => ({})),
        )
    );
