/* eslint-disable max-len */
import { OrderedSet } from "immutable";
import { lazy } from "react";

import * as OAS from "../App/utils/OAS3";
import { APIManagerRole } from "./AppTypes";

const Home = lazy(() => import("../App/components/09-views/02-pages/Home"));
const APIManager = lazy(() => import("../App/components/09-views/02-pages/API"));
const APIAbout = lazy(() => import("../App/components/09-views/00-blocks/API/Tabs/About"));

const Subscribers = lazy(() => import("../App/components/09-views/00-blocks/API/Tabs/Subscribers"));

const AppClients = lazy(() => import("../App/components/09-views/00-blocks/API/Tabs/AppClients"));

const APIConsumerAbout = lazy(() => import("../App/components/09-views/00-blocks/API/Tabs/Subscribers/DetailTabs/About"));
const ConsumerSecurity = lazy(() => import("../App/components/09-views/00-blocks/API/Tabs/Subscribers/DetailTabs/Security"));

const  UsagePlans = lazy(() => import("../App/components/09-views/00-blocks/API/Tabs/UsagePlans"));
const  UsagePlanAbout = lazy(() => import("../App/components/09-views/00-blocks/API/Tabs/UsagePlans/DetailTabs/About"));
const  Configuration = lazy(() => import("../App/components/09-views/00-blocks/API/Tabs/UsagePlans/DetailTabs/Configuration"));
const  ConsumerAbout = lazy(() => import("../App/components/09-views/00-blocks/Organisations/Tabs/Consumers/Tabs/About"));
const  Subscriptions = lazy(() => import("../App/components/09-views/00-blocks/Organisations/Tabs/Consumers/Tabs/Subscriptions"));
const  APIDetail = lazy(() => import("../App/components/09-views/02-pages/API/Detail"));
const  CreateSubscriber = lazy(() => import("../App/components/09-views/02-pages/APISubscribers/CreateSubscriber"));
const  APISubscriberDetail = lazy(() => import("../App/components/09-views/02-pages/APISubscribers/Detail"));
const  EditSubscriber = lazy(() => import("../App/components/09-views/02-pages/APISubscribers/EditSubscriber"));
const  CreateConsumer = lazy(() => import("../App/components/09-views/00-blocks/Organisations/Tabs/Consumers/CreateConsumer"));
const  ConsumerDetail = lazy(() => import("../App/components/09-views/00-blocks/Organisations/Tabs/Consumers/Detail"));
const  EditConsumer = lazy(() => import("../App/components/09-views/00-blocks/Organisations/Tabs/Consumers/EditConsumer"));
const  Organisations = lazy(() => import("../App/components/09-views/02-pages/Organisations"));
const  Consumers = lazy(() => import("../App/components/09-views/02-pages/Consumers"));
const  CreateOrg = lazy(() => import("../App/components/09-views/02-pages/Organisations/CreateOrg"));
const  OrgDetail = lazy(() => import("../App/components/09-views/02-pages/Organisations/Detail"));
const  EditOrg = lazy(() => import("../App/components/09-views/02-pages/Organisations/EditOrg"));
const  OrgAbout = lazy(() => import("../App/components/09-views/00-blocks/Organisations/Tabs/About"));
const  OrgConsumers = lazy(() => import("../App/components/09-views/00-blocks/Organisations/Tabs/Consumers"));
const  CreateUsageplan = lazy(() => import("../App/components/09-views/02-pages/UsagePlans/CreateUsagePlan"));
const  UsagePlanDetail = lazy(() => import("../App/components/09-views/02-pages/UsagePlans/Detail"));
const  EditUsagePlan = lazy(() => import("../App/components/09-views/02-pages/UsagePlans/EditUsagePlan"));
const  CertificateDetail = lazy(() => import("../App/components/09-views/00-blocks/API/Tabs/Certificates/Detail"));
const  CertificateAbout = lazy(() => import("../App/components/09-views/00-blocks/API/Tabs/Certificates/Tabs/About"));
const  CreateCertificate = lazy(() => import("../App/components/09-views/00-blocks/API/Tabs/Certificates/CreateCert"));
/**
 *
 */
export type DisplayOf<T extends string | number> = Readonly<Record<T, string>>;

/**
 *
 */
export const UserRolesDisplays: DisplayOf<APIManagerRole> = {
    [APIManagerRole.devops]: "DevOps",
    [APIManagerRole.audit]: "Read only",
    [APIManagerRole.admin]: "Super administrator",
    [APIManagerRole.NONE]: "Invalid role",
};

/**
 *
 */
export enum Pages {
    HOME = "HOME",
    API_MANAGEMENT = "API Management",
    ORGANIZATIONS = "ORGANIZATIONS",
    CONSUMERS = "CONSUMERS",
}

/**
 *
 */
export enum SubPages {
    API_DETAIL = "API_DETAIL",
    API_SUBSCRIBER_DETAIL = "API_SUBSCRIBER_DETAIL",
    API_SUBSCRIBER_CREATE = "API_SUBSCRIBER_CREATE",
    API_SUBSCRIBER_EDIT = "API_SUBSCRIBER_EDIT",
    USAGEPLAN_DETAIL = "USAGEPLAN_DETAIL",
    USAGEPLAN_CREATE = "USAGEPLAN_CREATE",
    USAGEPLAN_EDIT = "USAGEPLAN_EDIT",
    CONSUMER_DETAIL = "CONSUMER_DETAIL",
    CONSUMER_CREATE = "CONSUMER_CREATE",
    CONSUMER_EDIT = "CONSUMER_EDIT",
    ORGANIZATION_DETAIL = "ORGANIZATION_DETAIL",
    ORGANIZATION_CREATE = "ORGANIZATION_CREATE",
    ORGANIZATION_EDIT = "ORGANIZATION_EDIT",
    CERTIFICATE_DETAIL = "CERTIFICATE_DETAIL",
    CERTIFICATE_CREATE = "CERTIFICATE_CREATE",
}

/**
 *
 */
export enum PageTabs {
    API_DETAIL_ABOUT = "API_DETAIL_ABOUT",
    API_DETAIL_CERTIFICATES_ABOUT = "API_DETAIL_CERTIFICATES_ABOUT",
    API_DETAIL_SUBSCRIBERS = "API_DETAIL_SUBSCRIBERS",
    API_DETAIL_USAGEPLAN = "API_DETAIL_USAGEPLAN",
    API_DETAIL_APPCLIENT = "API_DETAIL_APPCLIENT",
    API_DETAIL_SUBSCRIBERS_ABOUT = "API_DETAIL_SUBSCRIBERS_ABOUT",
    API_DETAIL_SUBSCRIBERS_SECURITY = "API_DETAIL_SUBSCRIBERS_SECURITY",
    API_DETAIL_USAGEPLAN_ABOUT = "API_DETAIL_USAGEPLAN_ABOUT",
    API_DETAIL_USAGEPLAN_CONFIGURATION = "API_DETAIL_USAGEPLAN_CONFIGURATION",
    CONSUMER_ABOUT = "CONSUMER_ABOUT",
    CONSUMER_SUBSCRIPTIONS = "CONSUMER_SUBSCRIPTIONS",
    ORGANIZATION_ABOUT = "ORGANIZATION_ABOUT",
    ORGANIZATION_CONSUMERS = "ORGANIZATION_CONSUMERS",
}

export interface Tab {
    title: string;
    description: string;
    component?: React.FunctionComponent | React.ComponentState;
}

export interface Page {
    title: string;
    description: string;
    path: string;
    roles: string[];
    component?: React.FunctionComponent | React.ComponentState;
    beta?: boolean;
    parameters?: OAS.Parameter[];
    pagination?: boolean;
    responses?: OAS.Responses;
    parent?: Page;
    asMenu?: boolean;
    subpages: OrderedSet<SubPages>;
    tabs: OrderedSet<PageTabs>;
}

/**
 *
 */
export const PageDisplay: Readonly<Record<Pages, Page>> = {
    [Pages.HOME]: {
        title: "Dashboard",
        description: "You can do anything related to the management of the API's",
        component: Home,
        path: "/",
        roles: [APIManagerRole.admin, APIManagerRole.audit, APIManagerRole.devops],
        subpages: OrderedSet([]),
        tabs: OrderedSet([]),
    },
    [Pages.API_MANAGEMENT]: {
        title: "API Management",
        description: "You can manage the API's here, assign users, create and update certificates etc.",
        component: APIManager,
        path: "/api-management",
        roles: [APIManagerRole.admin, APIManagerRole.audit, APIManagerRole.devops],
        subpages: OrderedSet([
            SubPages.API_DETAIL,
            SubPages.API_SUBSCRIBER_CREATE,
            SubPages.API_SUBSCRIBER_DETAIL,
            SubPages.API_SUBSCRIBER_EDIT,
            SubPages.USAGEPLAN_CREATE,
            SubPages.USAGEPLAN_DETAIL,
            SubPages.USAGEPLAN_EDIT,
            SubPages.CERTIFICATE_CREATE,
            SubPages.CERTIFICATE_DETAIL,
        ]),
        tabs: OrderedSet([]),
    },
    [Pages.ORGANIZATIONS]: {
        title: "Organizations",
        description: "You can manage the organizations here.",
        component: Organisations,
        path: "/organizations",
        roles: [APIManagerRole.admin, APIManagerRole.audit, APIManagerRole.devops],
        subpages: OrderedSet([
            SubPages.ORGANIZATION_CREATE,
            SubPages.ORGANIZATION_DETAIL,
            SubPages.ORGANIZATION_EDIT,
            SubPages.CONSUMER_CREATE,
            SubPages.CONSUMER_DETAIL,
            SubPages.CONSUMER_EDIT,
        ]),
        tabs: OrderedSet([]),
    },
    [Pages.CONSUMERS]: {
        title: "Consumers",
        description: "You can view the consumers here.",
        component: Consumers,
        path: "/consumers",
        roles: [APIManagerRole.admin, APIManagerRole.devops],
        subpages: OrderedSet([]),
        tabs: OrderedSet([]),

    },
};

/**
 *
 */
export const SubPageDisplay: Readonly<Record<SubPages, Page>> = {
    [SubPages.API_DETAIL]: {
        title: "API detail",
        description: "You can see API details here",
        component: APIDetail,
        path: "/api-management/:apiId",
        roles: [APIManagerRole.admin, APIManagerRole.audit, APIManagerRole.devops],
        subpages: OrderedSet([]),
        tabs: OrderedSet([
            PageTabs.API_DETAIL_ABOUT,
            PageTabs.API_DETAIL_SUBSCRIBERS,
            PageTabs.API_DETAIL_USAGEPLAN,
            PageTabs.API_DETAIL_APPCLIENT,
        ]),
    },
    [SubPages.API_SUBSCRIBER_DETAIL]: {
        title: "Subscription detail",
        description: "You can see API subscription details here",
        component: APISubscriberDetail,
        path: "/api-management/:apiId/subscriptions/:subscriberId",
        roles: [APIManagerRole.admin, APIManagerRole.audit, APIManagerRole.devops],
        subpages: OrderedSet([]),
        tabs: OrderedSet([
            PageTabs.API_DETAIL_SUBSCRIBERS_ABOUT,
            PageTabs.API_DETAIL_SUBSCRIBERS_SECURITY,
        ]),
    },
    [SubPages.API_SUBSCRIBER_CREATE]: {
        title: "Add subscription",
        description: "You can add a API subscription here",
        component: CreateSubscriber,
        path: "/api-management/:apiId/subscriptions/create",
        roles: [APIManagerRole.admin, APIManagerRole.devops],
        subpages: OrderedSet([]),
        tabs: OrderedSet([]),
    },
    [SubPages.API_SUBSCRIBER_EDIT]: {
        title: "Edit subscription",
        description: "You can update a API subscription here",
        component: EditSubscriber,
        path: "/api-management/:apiId/subscriptions/:subscriberId/update",
        roles: [APIManagerRole.admin, APIManagerRole.devops],
        subpages: OrderedSet([]),
        tabs: OrderedSet([]),
    },
    [SubPages.USAGEPLAN_DETAIL]: {
        title: "Usage plan detail",
        description: "You can see API usage plan details here",
        component: UsagePlanDetail,
        path: "/api-management/:apiId/usageplans/:usageplanId",
        roles: [APIManagerRole.admin, APIManagerRole.audit, APIManagerRole.devops],
        subpages: OrderedSet([]),
        tabs: OrderedSet([
            PageTabs.API_DETAIL_USAGEPLAN_ABOUT,
            PageTabs.API_DETAIL_USAGEPLAN_CONFIGURATION,
        ]),
    },
    [SubPages.USAGEPLAN_CREATE]: {
        title: "Add usage plan",
        description: "You can create a API usage plan here",
        component: CreateUsageplan,
        path: "/api-management/:apiId/usageplans/create",
        roles: [APIManagerRole.admin, APIManagerRole.devops],
        subpages: OrderedSet([]),
        tabs: OrderedSet([]),
    },
    [SubPages.USAGEPLAN_EDIT]: {
        title: "Edit usage plan",
        description: "You can edit a API usage plan here",
        component: EditUsagePlan,
        path: "/api-management/:apiId/usageplans/:usageplanId/update",
        roles: [APIManagerRole.admin, APIManagerRole.devops],
        subpages: OrderedSet([]),
        tabs: OrderedSet([]),
    },
    [SubPages.CONSUMER_DETAIL]: {
        title: "Consumer detail",
        description: "You can see Consumer details here",
        component: ConsumerDetail,
        path: "/organizations/:orgId/consumers/:consumerId",
        roles: [APIManagerRole.admin, APIManagerRole.audit, APIManagerRole.devops],
        subpages: OrderedSet([]),
        tabs: OrderedSet([
            PageTabs.CONSUMER_ABOUT,
            PageTabs.CONSUMER_SUBSCRIPTIONS,
        ]),
    },
    [SubPages.CONSUMER_CREATE]: {
        title: "Add consumer",
        description: "You can create a consumer here",
        component: CreateConsumer,
        path: "/organizations/:orgId/consumers/create",
        roles: [APIManagerRole.admin, APIManagerRole.devops],
        subpages: OrderedSet([]),
        tabs: OrderedSet([]),
    },
    [SubPages.CONSUMER_EDIT]: {
        title: "Edit consumer",
        description: "You can edit a consumer here",
        component: EditConsumer,
        path: "/organizations/:orgId/consumers/:consumerId/update",
        roles: [APIManagerRole.admin, APIManagerRole.devops],
        subpages: OrderedSet([]),
        tabs: OrderedSet([]),
    },
    [SubPages.ORGANIZATION_DETAIL]: {
        title: "Organization detail",
        description: "You can see Organization details here",
        component: OrgDetail,
        path: "/organizations/:orgId",
        roles: [APIManagerRole.admin, APIManagerRole.audit, APIManagerRole.devops],
        subpages: OrderedSet([]),
        tabs: OrderedSet([
            PageTabs.ORGANIZATION_ABOUT,
            PageTabs.ORGANIZATION_CONSUMERS,
        ]),
    },
    [SubPages.ORGANIZATION_CREATE]: {
        title: "Add organization",
        description: "You can create an organization here",
        component: CreateOrg,
        path: "/organizations/create",
        roles: [APIManagerRole.admin, APIManagerRole.devops],
        subpages: OrderedSet([]),
        tabs: OrderedSet([]),
    },
    [SubPages.ORGANIZATION_EDIT]: {
        title: "Edit organization",
        description: "You can edit an organization here",
        component: EditOrg,
        path: "/organizations/:orgId/update",
        roles: [APIManagerRole.admin, APIManagerRole.devops],
        subpages: OrderedSet([]),
        tabs: OrderedSet([]),
    },
    [SubPages.CERTIFICATE_DETAIL]: {
        title: "Certificate detail",
        description: "You can see Certificate details here",
        component: CertificateDetail,
        path: "/api-management/:apiId/subscriptions/:subscriberId/certificates/:certId",
        roles: [APIManagerRole.admin, APIManagerRole.audit, APIManagerRole.devops],
        subpages: OrderedSet([]),
        tabs: OrderedSet([
            PageTabs.API_DETAIL_CERTIFICATES_ABOUT,
        ]),
    },
    [SubPages.CERTIFICATE_CREATE]: {
        title: "Add certificate",
        description: "You can create a certificate here",
        component: CreateCertificate,
        path: "/api-management/:apiId/subscriptions/:subscriberId/certificates/create",
        roles: [APIManagerRole.admin, APIManagerRole.devops],
        subpages: OrderedSet([]),
        tabs: OrderedSet([]),
    },
};

/**
 *
 */
export const PageTabsDisplay: Readonly<Record<PageTabs, Tab>> = {
    [PageTabs.API_DETAIL_ABOUT]: {
        title: "About",
        description: "You can see general API details here",
        component: APIAbout,
    },
    [PageTabs.API_DETAIL_SUBSCRIBERS]: {
        title: "Subscriptions",
        description: "You can see API subscriptions here",
        component: Subscribers,
    },
    [PageTabs.API_DETAIL_USAGEPLAN]: {
        title: "Usage plans",
        description: "You can see API usage plans here",
        component: UsagePlans,
    },
    [PageTabs.API_DETAIL_APPCLIENT]: {
        title: "App client",
        description: "You can see API client here",
        component: AppClients,
    },
    [PageTabs.API_DETAIL_SUBSCRIBERS_ABOUT]: {
        title: "About",
        description: "You can see general API consumer details here",
        component: APIConsumerAbout,
    },
    [PageTabs.API_DETAIL_SUBSCRIBERS_SECURITY]: {
        title: "Security",
        description: "You can see API consumer security details here",
        component: ConsumerSecurity,
    },
    [PageTabs.API_DETAIL_USAGEPLAN_ABOUT]: {
        title: "About",
        description: "You can see general API uage plan details here",
        component: UsagePlanAbout,
    },
    [PageTabs.API_DETAIL_USAGEPLAN_CONFIGURATION]: {
        title: "Configuration",
        description: "You can see API usage plan configuration details here",
        component: Configuration,
    },
    [PageTabs.CONSUMER_ABOUT]: {
        title: "About",
        description: "You can see general Consumer details here",
        component: ConsumerAbout,
    },
    [PageTabs.CONSUMER_SUBSCRIPTIONS]: {
        title: "Subscriptions",
        description: "You can see Consumer subscription details here",
        component: Subscriptions,
    },
    [PageTabs.ORGANIZATION_ABOUT]: {
        title: "About",
        description: "You can see general Organization details here",
        component: OrgAbout,
    },
    [PageTabs.ORGANIZATION_CONSUMERS]: {
        title: "Consumers",
        description: "You can see Organization consumer details here",
        component: OrgConsumers,
    },
    [PageTabs.API_DETAIL_CERTIFICATES_ABOUT]: {
        title: "About",
        description: "You can see Api certificate details here",
        component: CertificateAbout,
    },
};
