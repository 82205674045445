/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { AxiosResponse } from "axios";
import { API, APIDetail, APIDetails, APIRequest, APIs } from "./Types";

export const mapAPIRequests =
    (resp: AxiosResponse): APIs => {
        const requests = (resp.data.content ? resp.data.content as API[]
            : resp.data as API[]);

        const apis = {
            content: requests,
        };

        return apis;
    };

/**
 *
 * @param resp
 */
export const mapAPIDetailsRequests =
 (resp: AxiosResponse): APIDetails => {
     const request = (resp.data.content ? resp.data.content as APIDetail
         : resp.data as APIDetail);

     const api = {
         content: {
             ...request,
         },
     };

     return api;
 };

export const mapAPIUpdateRequest =
 (resp: AxiosResponse): APIRequest => resp.data as APIRequest;

