import { Action } from "redux";
import { ThunkAction, ThunkDispatch } from "redux-thunk";

import { ReduxState } from "./ReduxState";

/**
 * Thunk is the ThunkAction type used by Hermann for declaring thunks / action
 * creators that return thunks.
 *
 * In ThunkAction<R, S, E, A>:
 * R => Return value, a plain redux Action type returned by the thunk (if
 * applicable). May be void.
 * S => State type, return type of Dispatch<S> and getState().
 * E => ExtraArgument, unused.
 * A => ?
 */
export type Thunk<R extends Action<ActionTypeKeys> | void> =
    ThunkAction<R, ReduxState, undefined, Action<ActionTypeKeys>>;

/**
 * DispatchFunc gives an alias for the Dispatch function provided by Redux for
 * easier type handling.
 */
// export type DispatchFunc = Dispatch<Action<ActionTypeKeys>>;
export type DispatchFunc = ThunkDispatch<
ReduxState,
undefined,
Action<ActionTypeKeys>
>;

/**
 * StateFunc gives an aliad for the getState function provided by redux-thunk
 * for easier type handling.
 */
export type StateFunc = () => ReduxState;

/**
 * ReduxStateReducer
 */
export type ReducerFunc<S, A extends Action<ActionTypeKeys>> = (state: S, action: A) => S;

/*
 * Config specifies environment-related configuration parameters for the client.
 */
export type Config = Readonly<{
    apiEndpoint: string;
}>;

/**
 *
 */
export enum ActionTypeKeys {
    /**
     * APP_INIT is triggered when the App component is mounted, meaning the user
     * is successfully authenticated and we can start loading the first dataset.
     */
    APP_INIT = "APP_INIT",
    SIGN_OUT = "SIGN_OUT",
    REMOTE_TRIGGER = "REMOTE_TRIGGER",
    REMOTE_CANCEL = "REMOTE_CANCEL",
    REMOTE_RESPONSE = "REMOTE_SUCCESS",
    REMOTE_ERROR = "REMOTE_ERROR",
    REMOTE_CLEAR_ERROR = "REMOTE_CLEAR_ERROR",
    REMOTE_CLEAR_RESPONSE = "REMOTE_CLEAR_RESPONSE",
    UPDATE_PAGES = "UPDATE_PAGES",

    UPDATE_FILTERS = "UPDATE_FILTERS",
    CLEAR_FILTERS = "CLEAR_FILTERS",
    INIT_UPDATE_CERTIFICATE = "INIT_UPDATE_CERTIFICATE",
    UPDATE_CERTIFICATE = "UPDATE_CERTIFICATE",
    CLEAR_CERTIFICATE = "CLEAR_CERTIFICATE",
    INIT_UPDATE_APISUBSCRIBER = "INIT_UPDATE_APISUBSCRIBER",
    UPDATE_APISUBSCRIBER = "UPDATE_APISUBSCRIBER",
    CLEAR_APISUBSCRIBER = "CLEAR_APISUBSCRIBER",
    INIT_UPDATE_CONSUMER = "INIT_UPDATE_CONSUMER",
    UPDATE_CONSUMER = "UPDATE_CONSUMER",
    CLEAR_CONSUMER = "CLEAR_CONSUMER",
    UPDATE_CONSUMER_ON_ORG_CHANGE = "UPDATE_CONSUMER_ON_ORG_CHANGE",
    INIT_UPDATE_ORG = "INIT_UPDATE_ORG",
    UPDATE_ORG = "UPDATE_ORG",
    CLEAR_ORG = "CLEAR_ORG",
    UPDATE_APISUBSCRIBER_USAGE = "UPDATE_APISUBSCRIBER_USAGE",
    INIT_UPDATE_USAGEPLAN = "INIT_UPDATE_USAGEPLAN",
    UPDATE_USAGEPLAN = "UPDATE_USAGEPLAN",
    CLEAR_USAGEPLAN = "CLEAR_USAGEPLAN",
    UPDATE_CARD_COUNTER = "UPDATE_CARD_COUNTER",
    CLEAR_CARD_COUNTER = "CLEAR_CARD_COUNTER",
    TOGGLE_SIDEBAR = "TOGGLE_SIDEBAR",
    INIT_UPDATE_API = "INIT_UPDATE_API",
    UPDATE_API = "UPDATE_API",
    CLEAR_API = "CLEAR_API",
    INIT_UPDATE_APPCLIENT = "INIT_UPDATE_APPCLIENT",
    UPDATE_APPCLIENT = "UPDATE_APPCLIENT",
    CLEAR_APPCLIENT = "CLEAR_APPCLIENT",
}
