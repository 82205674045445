/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable max-len */
import React, { useEffect, useState } from "react";
import { useKeycloak } from "@react-keycloak/web";
import { connect } from "react-redux";
import { DispatchFunc } from "../../../store/ActionTypes";
import { appInit, signout } from "../../../store/AppActions";
import { APIManagerRole, UserInfo } from "../../../store/AppTypes";
import { LogoutBtn } from "./keycloaklogoutbtn";
import { ReduxState } from "../../../store/ReduxState";
import { Mary } from "@vwpfs/vwpfs-mary-react-comp-lib";


interface OwnProps {
    onAuthenticating?: React.ReactNode;
    onNotAllowed?: React.ReactNode;
}

interface DispatchProps {
    triggerInit: (userInfo?: UserInfo) => void;
    signout: () => void;
}

interface StateProps {
    token?: string;
}

type Props = React.PropsWithChildren<OwnProps & DispatchProps & StateProps>;

export const mapDispatchToProps = (dispatch: DispatchFunc): DispatchProps => ({
    triggerInit: (userInfo?: UserInfo) => dispatch(appInit(userInfo)),
    signout: () => dispatch(signout()),
});

const mapStateToProps = (s: ReduxState): StateProps => ({
    token: s.prop("user")?.isPresent() ? s.prop("user").get()?.token : undefined,
});


export const KeycloakAuthenticatedComp: React.FC<Props> = (props) => {
    const [hasError, setError] = useState(false);
    const { keycloak } = useKeycloak();
    useEffect(() => {
        if (keycloak?.token) {
            setError(false);
            const userRole = Object.values(APIManagerRole).filter(r => keycloak?.resourceAccess?.[process.env.REACT_APP_IRIS_CLIENTID ?? ""]?.roles.includes(r))[0];
            props.triggerInit({
                token: keycloak?.token,
                userName: (keycloak?.idTokenParsed as {name?: string})?.name  ?? "",
                userRole,
                keycloak: keycloak,
            });
        }

        if (keycloak) {
            keycloak.onAuthError = () => {
                setError(true);
            };
            keycloak.onTokenExpired = () => {
                console.warn("App:focus:keycloak:onTokenExpired: Update token");
                keycloak
                    .updateToken(50)
                    .then((_d) => {
                        console.info("Iris: Token updated");
                        localStorage.setItem("token", keycloak.token ?? "");
                    })
                    .catch(() => {
                        console.warn("Iris: Token not updated");
                    });
            };
        }
        if (!keycloak?.authenticated) {
            console.warn("Claude detects that you are not authenticated.");
            if (props.token) {
                props.signout();
            }
        } else {
            console.log("Claude has verified that you are authenticated with IRIS.");
        }

        const handleFocus = () => {
            try {
                if (!localStorage.getItem?.("token")) {
                    keycloak?.logout({redirectUri: process.env.REACT_APP_URL}).then(() => {
                        console.log("Iris: Logout success");
                    }).catch(() => {
                        console.warn("Iris: Logout failed");
                    });
                    localStorage.clear();
                }
            } catch (e) {
                console.warn("Claude can't handle focus of window:", e);
            }
        };

        window.addEventListener("focus", handleFocus);

        return () => {
            window.removeEventListener("focus", handleFocus);
        };
    });

    const resource_access =
    keycloak?.tokenParsed?.resource_access && keycloak?.tokenParsed?.azp
        ? keycloak?.tokenParsed?.resource_access?.[keycloak?.tokenParsed?.azp]
        : undefined;

    const hasValidAPIManagerRole = (resources?: { roles: string[] }) =>
        resources?.roles.some((role) => Object.values(APIManagerRole).includes(role as APIManagerRole));

    return (
        <>
            {hasError ? (
                <Mary.base.flex.FlexColumn isPage theme={{palette: Mary.theme.ThemePalette.CONTRAST_SECONDARY}} >
                    <Mary.base.flex.FlexRow isFixed>
                        <Mary.base.Container
                            theme={{
                                padding: { "": { y: 3 }, "sm": { y: 4 } },
                            }}
                        >
                            <Mary.base.Div className="scl-b-row">
                                <Mary.base.Grid
                                    offset={{ xs: 0, md: 3 }}
                                    size={{ xs: 12, md: 6 }}
                                    theme={{
                                        padding: {
                                            "": { b: 3, t: 1 },
                                            "md": { b: 4, t: 2 },
                                        },
                                    }}
                                >
                                    <Mary.base.Div theme={{shadow: Mary.theme.ThemeShadowSizes.TINY}}>
                                        <Mary.base.flex.FlexColumn>
                                            <Mary.base.flex.FlexRow isFixed>
                                                <Mary.organisms.navbar.Navbar
                                                    theme={{
                                                        palette: Mary.theme.ThemePalette.CONTRAST_TERTIARY,
                                                    }}
                                                >
                                                    <Mary.organisms.NavbarStack>
                                                        <Mary.organisms.NavbarStackContent>
                                                            <Mary.organisms.NavbarTitle>
                                                                Authentication failed
                                                            </Mary.organisms.NavbarTitle>
                                                        </Mary.organisms.NavbarStackContent>
                                                    </Mary.organisms.NavbarStack>
                                                </Mary.organisms.navbar.Navbar>
                                            </Mary.base.flex.FlexRow>
                                            <Mary.base.flex.FlexRow
                                                theme={{
                                                    palette: Mary.theme.ThemePalette.CONTRAST_PRIMARY,
                                                }}
                                            >
                                                <Mary.base.Container
                                                    theme={{
                                                        padding: {
                                                            "": { b: 3, t: 3 },
                                                            "md": { b: 4, t: 4 },
                                                        },
                                                    }}
                                                >
                                                    <Mary.organisms.RTE>Something went wrong, we can&apos;t seem to authenticate you.</Mary.organisms.RTE>
                                                </Mary.base.Container>
                                            </Mary.base.flex.FlexRow>
                                            <Mary.base.flex.FlexRow isFixed>
                                                <Mary.base.flex.FlexColumn
                                                    theme={{
                                                        palette: Mary.theme.ThemePalette.CONTRAST_TERTIARY,
                                                    }}
                                                >
                                                    <Mary.base.Container
                                                        theme={{
                                                            padding: { "": { y: 2 } },
                                                        }}
                                                    >
                                                        <Mary.molecules.ButtonsWrapper
                                                            orientations={{ [Mary.theme.ThemeBreakpoints.XS]: Mary.molecules.Orientation.HORIZONTAL}}
                                                            alignment={Mary.molecules.Alignment.CENTER}
                                                        >
                                                            <LogoutBtn noIcon />
                                                        </Mary.molecules.ButtonsWrapper>
                                                    </Mary.base.Container>
                                                </Mary.base.flex.FlexColumn>
                                            </Mary.base.flex.FlexRow>
                                        </Mary.base.flex.FlexColumn>
                                    </Mary.base.Div>
                                </Mary.base.Grid>
                            </Mary.base.Div>
                        </Mary.base.Container>
                    </Mary.base.flex.FlexRow>
                </Mary.base.flex.FlexColumn>) :
                keycloak?.authenticated &&
                hasValidAPIManagerRole(resource_access) &&
                props.children ? (
                        React.cloneElement(
                            props.children as React.FunctionComponentElement<{
                                authenticated?: boolean;
                            }>,
                            { authenticated: keycloak?.authenticated },
                        )
                    ) : keycloak?.authenticated &&
                !hasValidAPIManagerRole(resource_access) &&
                props.onNotAllowed ? (
                            props.onNotAllowed
                        ) : props.onAuthenticating ? (
                            props.onAuthenticating
                        ) : null}
        </>
    );
};

/**
 *
 */
export const KeycloakAuthenticated = connect(
    mapStateToProps,
    mapDispatchToProps,
)(KeycloakAuthenticatedComp);

