import { Action } from "redux";
import { ActionTypeKeys } from "../ActionTypes";
import { ReducerFn } from "../ReduxState";
import { RemoteScope } from "../RemoteTypes";
import { OrganisationRequest } from "./Types";
import { Mary } from "@vwpfs/vwpfs-mary-react-comp-lib";

/**
 *
 */
export type InitUpdateOrgAction = Readonly<
Action<ActionTypeKeys.INIT_UPDATE_ORG> & {}>;

/**
 *
 *
 */
export const initUpdateOrg =
    (): InitUpdateOrgAction => ({
        type: ActionTypeKeys.INIT_UPDATE_ORG,
    });

/**
 *
 * @param s
 * @param a
 */
export const initUpdateOrgReducer:
ReducerFn<InitUpdateOrgAction> =
    (s, _a) => {
        const orgDetails = s.prop("remote").prop(RemoteScope.ORGANISATION_DETAILS);
        return (
            s.setProp(
                "orgRequest",
                s.prop("orgRequest").update(() => orgDetails?.content ?? {}),
            )
        );
    };

/**
 *
 */
export type UpdateOrgAction = Readonly<
Action<ActionTypeKeys.UPDATE_ORG> & {
    org?: Partial<OrganisationRequest>;
}
>;

/**
 *
 *
 */
export const updateOrg =
    (org: OrganisationRequest): UpdateOrgAction => ({
        type: ActionTypeKeys.UPDATE_ORG,
        org: org,
    });

/**
 *
 * @param s
 * @param a
 */
export const updateOrgReducer:
ReducerFn<UpdateOrgAction> =
    (s, a) => (
        s.setProp(
            "orgRequest",
            s.prop("orgRequest").updateWith(
                a.org ?? {},
            ),
        )
    );

/**
 *
 */
export type ClearOrgAction = Readonly<
Action<ActionTypeKeys.CLEAR_ORG>
>;

/**
 *
 * @param keys
 */
export const clearOrg:
() => ClearOrgAction =
    () => ({
        type: ActionTypeKeys.CLEAR_ORG,
    });

/**
 *
 */
export const clearOrgReducer:
ReducerFn<ClearOrgAction> =
    (s, _a) => (
        s.setProp(
            "orgRequest",
            Mary.utils.State.create<OrganisationRequest>(() => ({})),
        )
    );
