/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { AxiosResponse } from "axios";
import { ReduxState } from "../ReduxState";
import { RemoteScope } from "../RemoteTypes";
import { Certificate, CertificateDetail, CertificateDetails, Certificates } from "./Types";


export const mapCertificateRequests =
    (resp: AxiosResponse, s: ReduxState): Certificates => {
        const requests = (resp.data.content ? resp.data.content as Certificate[]
            : resp.data as Certificate[]);

        const apiDetails = s.prop("remote").prop(RemoteScope.API_DETAILS);

        const certificates = {
            content: apiDetails ? requests.map(subscriber => ({
                ...subscriber,
                apiName: apiDetails?.content.name,
            })) : [],
        };

        return certificates;
    };

/**
 *
 * @param resp
 */
export const mapCertificateDetailsRequests =
 (resp: AxiosResponse, s: ReduxState): CertificateDetails => {
     const request = (resp.data.content ? resp.data.content as CertificateDetail
         : resp.data as CertificateDetail);

     const apiDetails = s.prop("remote").prop(RemoteScope.API_DETAILS);

     const certificate = {
         content: apiDetails ? {
             ...request,
             apiName: apiDetails?.content.name,
         } : {} as CertificateDetail,
     };

     return certificate;
 };

/**
 *
 * @param resp
 */
export const mapCertificateDetailsRequestsOnCreate =
(resp: AxiosResponse): CertificateDetails => {
    const request = (resp.data.content ? resp.data.content as CertificateDetail
        : resp.data as CertificateDetail);

    const certificate = {
        content: request,
    };

    return certificate;
};
