import { Action } from "redux";
import { ActionTypeKeys } from "../ActionTypes";
import { ReducerFn } from "../ReduxState";
import { RemoteScope } from "../RemoteTypes";
import { UsagePlanRequest } from "./Types";
import { Mary } from "@vwpfs/vwpfs-mary-react-comp-lib";

/**
 *
 */
export type InitUpdateUsagePlanAction = Readonly<
Action<ActionTypeKeys.INIT_UPDATE_USAGEPLAN> & {
    id: string;
}
>;

/**
 *
 *
 */
export const initUpdateUsagePlan =
    (id: string): InitUpdateUsagePlanAction => ({
        type: ActionTypeKeys.INIT_UPDATE_USAGEPLAN,
        id: id,
    });

/**
 *
 * @param s
 * @param a
 */
export const initUpdateUsagePlanReducer:
ReducerFn<InitUpdateUsagePlanAction> =
    (s, _a) => {
        const usageplanDetails = s.prop("remote").prop(RemoteScope.USAGEPLAN_DETAILS);
        return (
            s.setProp(
                "usageplanRequest",
                s.prop("usageplanRequest").update(() => usageplanDetails
                    ? {
                        ...usageplanDetails.content,
                        throttle: {
                            ...usageplanDetails.content.throttle,
                            disabled: usageplanDetails.content.throttle ? undefined : true,
                        },
                        quota: {
                            ...usageplanDetails.content.quota,
                            disabled: usageplanDetails.content.quota ? undefined : true,
                        },
                        throttlingEnabled: usageplanDetails.content.throttle ? true : false,
                        quotaEnabled: usageplanDetails.content.quota ? true : false,
                    }
                    : {}),
            )
        );
    };

/**
 *
 */
export type UpdateUsagePlanAction = Readonly<
Action<ActionTypeKeys.UPDATE_USAGEPLAN> & {
    usageplan?: Partial<UsagePlanRequest>;
}
>;

/**
 *
 *
 */
export const updateUsagePlan =
    (usageplan: UsagePlanRequest): UpdateUsagePlanAction => ({
        type: ActionTypeKeys.UPDATE_USAGEPLAN,
        usageplan: usageplan,
    });

/**
 *
 * @param s
 * @param a
 */
export const updateUsagePlanReducer:
ReducerFn<UpdateUsagePlanAction> =
    (s, a) => (
        s.setProp(
            "usageplanRequest",
            s.prop("usageplanRequest").updateWith(
                a.usageplan ?? {},
            ),
        )
    );

/**
 *
 */
export type ClearUsagePlanAction = Readonly<
Action<ActionTypeKeys.CLEAR_USAGEPLAN>
>;

/**
 *
 * @param keys
 */
export const clearUsagePlan:
() => ClearUsagePlanAction =
    () => ({
        type: ActionTypeKeys.CLEAR_USAGEPLAN,
    });

/**
 *
 */
export const clearUsagePlanReducer:
ReducerFn<ClearUsagePlanAction> =
    (s, _a) => (
        s.setProp(
            "usageplanRequest",
            Mary.utils.State.create<UsagePlanRequest>(() => ({})),
        )
    );
