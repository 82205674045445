import { Mary } from "@vwpfs/vwpfs-mary-react-comp-lib";
import React from "react";

interface Props {
}

export const NoRole: React.FunctionComponent<Props> = () => (
    <Mary.base.flex.FlexRow isFixed>
        <Mary.base.Container
            style={{display: "flex", alignItems: "center", justifyContent: "center"}}
            className="scl-h-text-align--center"
            theme={{
                padding: { "": { y: 3 }, "sm": { y: 4 } },
            }}
        >
            <Mary.views.blocks.LostAndFound
                title={Mary.utils.getText("app.no-role-active", "No role active") ?? ""}
                // eslint-disable-next-line max-len
                description={Mary.utils.getText("app.no-role-active-description", "You need a role to continue, without a role you can't use this application.") ?? ""} />
        </Mary.base.Container>
    </Mary.base.flex.FlexRow>
);
